// libs
import { SxProps } from "@mui/material";
import { Action, Primary } from "../../../styles/colors";

export const sideMenuDrawer: SxProps = {
    ".MuiPaper-root": {
        borderRight: "none",
    },
};

export const sideMenu: SxProps = {
    height: "100%",
};

export const sideMenuList: SxProps = {
    pt: 0,
};

export const sideMenuItem = (heading: boolean): SxProps => ({
    pointerEvents: heading ? "none" : "all",
});

export const sideMenuItemIcon = (heading: boolean): SxProps => ({
    minWidth: "30px",
    color: heading ? Primary[70] : Primary[0],
});

export const sideMenuItemText = (open: boolean, heading: boolean): SxProps => ({
    opacity: open ? 1 : 0,
    color: heading ? Primary[70] : Primary[0],
});

export const sideMenuItemButton = (open: boolean, heading: boolean): SxProps => ({
    minHeight: heading ? 48 : 20,
    justifyContent: open ? "initial" : "center",
    px: 2.5,
    pb: 0,
    pt: heading ? 1 : 0,
});

export const sideMenuItemDivider: SxProps = {
    mt: 2,
    border: `1px solid ${Primary[90]}`,
};

export const sideMenuSandboxSelection: SxProps = {
    mt: 4,
    mx: 2,
    mb: 1,

    ".MuiInputBase-root": {
        background: Primary.white,
    },

    ".MuiSelect-select": {
        maxWidth: "11rem",
    },
};

export const sideMenuAllocationTypeSelection: SxProps = {
    mt: 1,
    mx: 2,
    mb: 1,
    ".MuiInputBase-root": {
        background: Primary.white,
    },
};

export const sideMenuSandboxControls: SxProps = {
    px: 2.5,
    my: 2,
};

export const allocationMCMTool: SxProps = {
    mx: 2.5,
    my: 1,
    py: 1,
    px: 1.5,
    borderRadius: "4px",
    width: "calc(100% - 32px)",
    background: Primary[95],
    gap: "4px",
};

export const allocationMCMToolTitle: SxProps = {
    display: "flex",
    columnGap: "4px",
    flexDirection: "row",
    alignItems: "center",
};

export const allocationMCMToolDiscription: SxProps = {
    maxWidth: "200px",
    color: Action.main,
};
