// libs
import { useCallback } from "react";

// services
import { LoggerService } from "@services/LoggerService";

// defs
export enum LogLevelMethod {
    ERROR = "error",
    INFO = "info",
    WARN = "warn",
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function useLogger() {
    // function for publishing the error logs.
    const publishErrorLog = useCallback((logErrorMessages: Array<string>): void => {
        LoggerService.getLoggerServiceInstance().publishLog(LogLevelMethod.ERROR, logErrorMessages);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // function for publishing the warning logs.
    const publishWarningLog = useCallback((logErrorMessages: Array<string>): void => {
        LoggerService.getLoggerServiceInstance().publishLog(LogLevelMethod.WARN, logErrorMessages);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // function for publishing the info logs.
    const publishInfoLog = useCallback((logErrorMessages: Array<string>): void => {
        LoggerService.getLoggerServiceInstance().publishLog(LogLevelMethod.INFO, logErrorMessages);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { publishErrorLog, publishWarningLog, publishInfoLog };
}

export default useLogger;
