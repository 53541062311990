// libs
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

// components
import { Modal, Grid, Stack, Typography, LinearProgress } from "@mui/material";

// colors
import { Success } from "../../styles/colors";

// styles
import { loadingModalContainer, loadingModalStack, loadingModal } from "./LoadingModal.style";

export interface ILoadingModalProps {
    forceLoading?: boolean;
}

const LoadingModal: React.FC<ILoadingModalProps> = ({ forceLoading = false }: ILoadingModalProps) => {
    const loading = useSelector(({ loader }: any) => loader.loadingCount > 0);

    const showLoading = useMemo(() => !!loading || !!forceLoading, [loading, forceLoading]);

    return (
        <Modal open={showLoading} disableEscapeKeyDown={true} onClose={() => false}>
            <Grid container alignContent="center" justifyContent="center" sx={loadingModalContainer}>
                <Stack alignItems="center" sx={loadingModalStack} spacing={1}>
                    <Typography color={Success.white} variant="h6">
                        Loading...
                    </Typography>
                    <LinearProgress sx={loadingModal} />
                </Stack>
            </Grid>
        </Modal>
    );
};

export default React.memo(LoadingModal);
