// libs

import { SxProps } from "@mui/material";

export const linearLoaderContainer: SxProps = {
    width: "100vw",
    height: "100vh",
};

export const linearLoaderStack: SxProps = {
    width: "100%",
};

export const linearLoader: SxProps = {
    width: "50%",

    ".MuiLinearProgress-bar": {
        width: "20%",
    },
};
