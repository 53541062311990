// libs
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { UseMutationResult, useMutation } from "@tanstack/react-query";

// utils
import { measureLatency } from "@helpers/index";
import { formatGraphQLError } from "@helpers/Error";
import { getClient, graphQlQueries } from "@utils/appsync";
import { CreateSIMTicketResponse } from "@utils/appsync/schema/API";

// hooks
import { useLogger } from "@hooks/useLogger";
import { useMetrics } from "@hooks/useMetrics";

// constants
import { MetricName } from "@constants/Metrics";
import { ServiceType } from "@constants/Services";

export const useCreateSimTicket = (): UseMutationResult<
    CreateSIMTicketResponse,
    Error,
    { userName: string; title: string; description: string }
> => {
    const { publishErrorLog } = useLogger();

    const { publishFailureMetric, publishLatencyMetric } = useMetrics();

    return useMutation<CreateSIMTicketResponse, Error, { userName: string; title: string; description: string }>({
        mutationFn: async ({ userName, title, description }) => {
            const startTime = Date.now();

            const { data, errors }: GraphQLResult<any> = await getClient().graphql({
                query: graphQlQueries.createSIMTicket,
                variables: {
                    input: { title, description, service: "A3S", requestor: userName },
                },
            });

            // publish the createSIMTicket service latency metrics
            publishLatencyMetric(MetricName.CREATE_SIM_TICKET_LATENCY, measureLatency(startTime), ServiceType.SimTicketService);

            // in case of createSIMTicket mutation failure, publish the logs and metrics to cloudwatch
            if (errors?.length) {
                // publishing the error logs
                publishErrorLog(errors.map((error) => formatGraphQLError(error)));

                // publish the failure metrics
                publishFailureMetric(MetricName.CREATE_SIM_TICKET_ERROR_COUNT, ServiceType.SimTicketService);
            }

            return data.createSIMTicket;
        },
    });
};
