/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateReviewInput = {
  requester: string,
  system: string,
  diffJson: string,
  reviewDescription?: string | null,
  resourceIdentifier: string,
};

export type StatusOutput = {
  __typename: "StatusOutput",
  status?: string | null,
};

export type CreateSystemInput = {
  systemId: string,
  systemDescription?: string | null,
  reviewerReqCount?: number | null,
  systemAllowList?: string | null,
  ldapGroup?: string | null,
};

export type CreateUserInput = {
  userId: string,
  userName?: string | null,
};

export type AddCommentInput = {
  reviewId: string,
  userId: string,
  comment?: string | null,
};

export type ManageReviewRequest = {
  reviewId: string,
  action: Action,
};

export enum Action {
  CANCEL_REVIEW = "CANCEL_REVIEW",
  MERGE_REVIEW = "MERGE_REVIEW",
}


export type ManageReviewerReviewRequest = {
  userId: string,
  reviewId: string,
  reviewerStatus: ReviewerStatus,
};

export enum ReviewerStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  DENIED = "DENIED",
}


export type ManageReviewerReviewResponse = {
  __typename: "ManageReviewerReviewResponse",
  status?: string | null,
  review?: ReviewInfo | null,
  reviewStatus?: string | null,
  configStatus?: string | null,
};

export type ReviewInfo = {
  __typename: "ReviewInfo",
  reviewId?: string | null,
  reviewStatus?: ReviewStatus | null,
  reviewJson?: string | null,
  system?: string | null,
  timestamp?: string | null,
  requesterId?: string | null,
  resourceIdentifier?: string | null,
  reviewers?: Array< string | null > | null,
  reviewDescription?: string | null,
  reviewedTimestamp?: string | null,
};

export enum ReviewStatus {
  PENDING = "PENDING",
  MERGED = "MERGED",
  APPROVED = "APPROVED",
  DENIED = "DENIED",
  CANCELLED = "CANCELLED",
}


export type CreateConfigWithReviewInput = {
  system: string,
  reviewDescription?: string | null,
  tenantName: string,
  configName: string,
  parentConfig: string,
  versionDescription: string,
  configType: ConfigType,
  configsJson?: string | null,
  user: string,
  version?: number | null,
};

export enum ConfigType {
  global = "global",
  parent = "parent",
  child = "child",
  CONFIGURATION = "CONFIGURATION",
  FEATURE_FLAG = "FEATURE_FLAG",
}


export type CreateConfigWithReviewOutput = {
  __typename: "CreateConfigWithReviewOutput",
  reviewStatus?: string | null,
  status?: string | null,
};

export type CreateVersionWithReviewInput = {
  system: string,
  reviewDescription?: string | null,
  tenantName: string,
  configName: string,
  parentConfig: string,
  versionDescription: string,
  configType: ConfigType,
  configsJson?: string | null,
  user: string,
  version?: number | null,
};

export type CreateVersionWithReviewOutput = {
  __typename: "CreateVersionWithReviewOutput",
  reviewStatus?: string | null,
  status?: string | null,
};

export type CreateConfigurationRequest = {
  tenantName: string,
  configName: string,
  parentConfig: string,
  versionDescription: string,
  configType: ConfigType,
  configsJson?: string | null,
  user: string,
};

export type CreateConfigurationResponse = {
  __typename: "CreateConfigurationResponse",
  string?: string | null,
};

export type CreateTenantRequest = {
  tenantName: string,
  configsJson?: string | null,
  user: string,
  bindleID: string,
};

export type CreateGFTOneNotificationRequestInput = {
  notificationType: NotificationType,
  data?: NotificationDataInput | null,
  app: string,
  stage: string,
  channels?: Array< string > | null,
  recipients?: Array< string > | null,
  isSplitGroup?: boolean | null,
  eventType: string,
  resourceId?: string | null,
  sender?: string | null,
};

export enum NotificationType {
  TRANSACTIONAL = "TRANSACTIONAL",
  NON_TRANSACTIONAL = "NON_TRANSACTIONAL",
}


export type NotificationDataInput = {
  title?: string | null,
  description?: string | null,
  content?: string | null,
  additionalInfo?: string | null,
  redirectTo?: string | null,
};

export type CreateGFTOneNotificationRequestOutPut = {
  __typename: "CreateGFTOneNotificationRequestOutPut",
  allNotifications?:  Array<eachNotification | null > | null,
};

export type eachNotification = {
  __typename: "eachNotification",
  id?: string | null,
  app?: string | null,
  channel?: string | null,
  stage?: string | null,
  data?: NotificationDetail | null,
  recipients?: Array< string | null > | null,
  notificationType?: NotificationType | null,
  isSplitGroup?: boolean | null,
  sender?: string | null,
};

export type NotificationDetail = {
  __typename: "NotificationDetail",
  title?: string | null,
  description?: string | null,
  content?: string | null,
  additionalInfo?: string | null,
  redirectTo?: string | null,
};

export type CreateGFTOneUserSubscriptionRequest = {
  recipient: string,
  app: string,
  stage: string,
  eventType: string,
  resourceId?: string | null,
  channel: string,
  createdOn?: number | null,
  lastModifiedOn?: number | null,
  expiresOn?: number | null,
};

export type CreateGFTOneUserSubscriptionResponse = {
  __typename: "CreateGFTOneUserSubscriptionResponse",
  id: string,
  status?: string | null,
  app?: string | null,
};

export type DeleteGFTOneUserSubscriptionRequest = {
  id: string,
};

export type DeleteGFTOneUserSubscriptionResponse = {
  __typename: "DeleteGFTOneUserSubscriptionResponse",
  id: string,
  status?: string | null,
};

export type UpdateGFTOneUserSubscriptionRequest = {
  id: string,
  recipient?: string | null,
  app?: string | null,
  stage?: string | null,
  eventType?: string | null,
  resourceId?: string | null,
  channel?: string | null,
  createdOn?: number | null,
  lastModifiedOn?: number | null,
  expiresOn?: number | null,
};

export type UpdateGFTOneUserSubscriptionResponse = {
  __typename: "UpdateGFTOneUserSubscriptionResponse",
  id: string,
  status?: string | null,
};

export type UpdateGFTOneUINotificationRequest = {
  id: string,
  recipient?: string | null,
  app?: string | null,
  stage?: string | null,
  eventType?: string | null,
  resourceId?: string | null,
  channel?: string | null,
  createdOn?: number | null,
  lastModifiedOn?: number | null,
  expiresOn?: number | null,
  read?: boolean | null,
};

export type UpdateGFTOneUINotificationResponse = {
  __typename: "UpdateGFTOneUINotificationResponse",
  id: string,
  status?: string | null,
};

export type GFTOneNotificationsInput = {
  alias: string,
  stage: string,
  system?: string | null,
  seen?: boolean | null,
};

export type getListOfNotifications = {
  __typename: "getListOfNotifications",
  items?:  Array<UINotification | null > | null,
};

export type UINotification = {
  __typename: "UINotification",
  id?: string | null,
  app?: string | null,
  channel?: string | null,
  alias?: string | null,
  created_at?: string | null,
  eventType?: string | null,
  stageSystemKey?: string | null,
  seen?: boolean | null,
  read?: boolean | null,
  data?: NotificationDetail | null,
  sender?: string | null,
};

export type CreateBindleResourceRequest = {
  ownerBindleID: string,
  application: string,
  stage: string,
  resourceType: string,
  name: string,
  requestor: string,
};

export type CreateBindleResourceResponse = {
  __typename: "CreateBindleResourceResponse",
  resourceID?: string | null,
  status?: string | null,
};

export type CreateSandboxRequest = {
  sandbox_name?: string | null,
  bi_role?: string | null,
  allocation_types?: Array< AllocationType | null > | null,
};

export enum AllocationType {
  opex_allocation = "opex_allocation",
  gl_simulation = "gl_simulation",
}


export type Sandbox = {
  __typename: "Sandbox",
  sandbox_id: string,
  resource_key: string,
  status_key?: string | null,
  resource_id?: string | null,
  resource_type?: ResourceType | null,
  is_active?: boolean | null,
  created_at?: number | null,
  status?: SandboxStatus | null,
  sandbox_name?: string | null,
  allocation_types?: Array< AllocationType | null > | null,
};

export enum ResourceType {
  SANDBOX = "SANDBOX",
  INPUT = "INPUT",
  SIM = "SIM",
  SIM_SHARED = "SIM_SHARED",
  REPORT = "REPORT",
}


export enum SandboxStatus {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
}


export type UpdateSandboxRequest = {
  sandbox_name?: string | null,
  status?: string | null,
  allocation_types?: Array< AllocationType | null > | null,
};

export type UserInput = {
  __typename: "UserInput",
  sandbox_id: string,
  resource_key: string,
  status_key: string,
  resource_id: string,
  resource_type: ResourceType,
  is_active: boolean,
  created_at: number,
  status: InputStatus,
  input_type: InputType,
  allocation_type: AllocationType,
  s3_path: string,
  userAlias?: string | null,
  dictionary_input_id?: string | null,
};

export enum InputStatus {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
}


export enum InputType {
  allocation_mapping = "allocation_mapping",
  allocation_definition = "allocation_definition",
  manual_journal_entry = "manual_journal_entry",
}


export type Sim = {
  __typename: "Sim",
  sandbox_id: string,
  resource_key: string,
  status_key: string,
  resource_id: string,
  resource_type: ResourceType,
  is_active: boolean,
  name?: string | null,
  status: SimStatus,
  created_at: number,
  updated_at?: number | null,
  started_at?: number | null,
  finished_at?: number | null,
  error_msg?: string | null,
  // string/null if status is FAILED/otherwise
  configs?: string | null,
  allocation_type: AllocationType,
  input_ids?: Array< string | null > | null,
  mwaa_env?: string | null,
  dag_run_id?: string | null,
  scenario?: string | null,
  // Using string instead of enum to support names like "R&O Current", without having to map manually
  timeRange?: Array< string | null > | null,
  dag_tasks_status?: string | null,
  // json string `{task_id: task_state}`. TODO: convert to object representation for better control on content
  report_id?: string | null,
  is_shared?: boolean | null,
  // sharing source parameters
  shared_to_sandbox_ids?: Array< string | null > | null,
  // sharing target parameters
  shared_from_sandbox_id?: string | null,
  userAlias: string,
};

export enum SimStatus {
  CREATED = "CREATED",
  STARTED = "STARTED",
  CANCELED = "CANCELED",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
}


export type CreateSimRequest = {
  name?: string | null,
  configs?: string | null,
  allocation_type?: AllocationType | null,
  scenario?: string | null,
  timeRange?: Array< string | null > | null,
  input_ids?: Array< string | null > | null,
  userAlias: string,
};

export type UpdateSimRequest = {
  name?: string | null,
  configs?: string | null,
  allocation_type?: AllocationType | null,
  scenario?: string | null,
  timeRange?: Array< string | null > | null,
  input_ids?: Array< string | null > | null,
  userAlias?: string | null,
};

export type UpdateSimStatusRequest = {
  status?: SimStatus | null,
  error_msg?: string | null,
  mwaa_env?: string | null,
  dag_run_id?: string | null,
  dag_tasks_status?: string | null,
  report_id?: string | null,
  userAlias: string,
};

export type ShareSimRequest = {
  share_to_sandbox_id: string,
  userAlias: string,
};

export type UpdateConfigurationResponse = {
  __typename: "UpdateConfigurationResponse",
  content?: string | null,
};

export type CreateScenarioRequest = {
  scenario_name: string,
  start_period: string,
  end_period: string,
  default_public: boolean,
  display_period: boolean,
};

export type Scenario = {
  __typename: "Scenario",
  scenario_name: string,
  start_period: string,
  end_period: string,
  default_public: boolean,
  created_at: number,
  status: ScenarioStatus,
  status_key: string,
  resource_key: string,
  display_period: boolean,
  resource_id: string,
  sandbox_id?: string | null,
  allocation_type?: AllocationType | null,
};

export enum ScenarioStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}


export type UpdateScenarioRequest = {
  scenario_name: string,
  start_period: string,
  end_period: string,
  default_public: boolean,
  display_period: boolean,
  resource_id: string,
};

export type EnableScenarioRequest = {
  scenario_name: string,
  resource_id: string,
};

export type DisableScenarioRequest = {
  scenario_name: string,
  resource_id: string,
};

export type ValidateAndExportRequest = {
  inputName: string,
  user: string,
  data: Array< Array< string | null > >,
  attributeList: Array< string >,
};

export type ValidateAndExportResponse = {
  __typename: "ValidateAndExportResponse",
  errors?:  Array<ErrorDetail | null > | null,
  dataTarget?: DataTargetDetail | null,
  status?: ExportStatus | null,
  message?: string | null,
};

export type ErrorDetail = {
  __typename: "ErrorDetail",
  rowNumber?: number | null,
  attributeName?: string | null,
  attributeValue?: string | null,
  errorMessage?: string | null,
  suggestion?: string | null,
};

export type DataTargetDetail = {
  __typename: "DataTargetDetail",
  dataTargetType?: string | null,
  filePath?: string | null,
};

export enum ExportStatus {
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  INVALID = "INVALID",
}


export type FetchReviewsRequest = {
  system: string,
  beginTimestamp?: string | null,
  endTimestamp?: string | null,
  resourceIdentifier?: string | null,
  activeStatus?: ActiveStatus | null,
  fullReview?: boolean | null,
  limit?: number | null,
  user?: string | null,
};

export enum ActiveStatus {
  ACTIVE = "ACTIVE",
  CLOSED = "CLOSED",
  ALL = "ALL",
}


export type FetchReviewsResponse = {
  __typename: "FetchReviewsResponse",
  reviews?:  Array<ReviewInfo | null > | null,
};

export type FetchReviewsByUserRequest = {
  userId: string,
};

export type FetchReviewsByUserResponse = {
  __typename: "FetchReviewsByUserResponse",
  reviewsRequestedByUser?:  Array<ReviewInfo | null > | null,
  reviewsToBeReviewedByUser?:  Array<ReviewInfo | null > | null,
};

export type CheckSystemPermissionInput = {
  systemId: string,
  userId: string,
};

export type CheckSystemPermissionOutput = {
  __typename: "CheckSystemPermissionOutput",
  hasAccess?: boolean | null,
};

export type FetchReviewByIdRequest = {
  reviewId: string,
};

export type FetchReviewByIdResponse = {
  __typename: "FetchReviewByIdResponse",
  review?: ReviewInfo | null,
};

export type GetConfigurationRequest = {
  tenantName: string,
  configName: string,
  version?: number | null,
};

export type GetConfigurationResponse = {
  __typename: "GetConfigurationResponse",
  configs?: string | null,
  metadata?: string | null,
  user?: string | null,
};

export type TenantsList = {
  __typename: "TenantsList",
  tenants?: Array< string > | null,
};

export type GetListOfConfigsRequest = {
  tenant: string,
};

export type ConfigsList = {
  __typename: "ConfigsList",
  configs?:  Array<ConfigInfo | null > | null,
};

export type ConfigInfo = {
  __typename: "ConfigInfo",
  configName: string,
  configType?: ConfigType | null,
  parentConfig?: string | null,
};

export type RetrieveConfigListInput = {
  configName: string,
  version?: number | null,
};

export type RetrieveConfigurationsResponse = {
  __typename: "RetrieveConfigurationsResponse",
  configsMap?:  Array<ConfigMapList | null > | null,
};

export type ConfigMapList = {
  __typename: "ConfigMapList",
  configName?: string | null,
  parentConfig?: string | null,
  configType?: ConfigType | null,
  configParams?: string | null,
  versionDescription?: string | null,
  metadata?: string | null,
  user?: string | null,
};

export type GetListOfAccessibleTenantsRequest = {
  user: string,
  operation?: string | null,
};

export type GetListOfAccessibleTenantsResponse = {
  __typename: "GetListOfAccessibleTenantsResponse",
  tenants?:  Array<TenantInfo > | null,
};

export type TenantInfo = {
  __typename: "TenantInfo",
  tenantName?: string | null,
  operation?: string | null,
};

export type GetSyncStatusResponse = {
  __typename: "GetSyncStatusResponse",
  statusCode?: number | null,
  data?: SyncStatus | null,
};

export type SyncStatus = {
  __typename: "SyncStatus",
  planSync?:  Array<SyncStatusJobDetail | null > | null,
  actualsSync?:  Array<SyncStatusJobDetail | null > | null,
};

export type SyncStatusJobDetail = {
  __typename: "SyncStatusJobDetail",
  startPeriod?: string | null,
  endPeriod?: string | null,
  jobTriggerTime?: string | null,
  jobStartTime?: string | null,
  jobNAWSEndTime?: string | null,
  jobEDXEndTime?: string | null,
  jobGalaxyEndTime?: string | null,
};

export type IsAuthorizedRequest = {
  resourceID?: string | null,
  actorID?: string | null,
};

export type IsAuthorizedResponse = {
  __typename: "IsAuthorizedResponse",
  isAuthorized?: boolean | null,
};

export type GetDashboardEmbedUrlRequest = {
  userName?: string | null,
  dashboardName?: QuicksightDashboardEnum | null,
};

export enum QuicksightDashboardEnum {
  ALLOCATION_TRACING = "ALLOCATION_TRACING",
}


export type GetDashboardEmbedUrlResponse = {
  __typename: "GetDashboardEmbedUrlResponse",
  url?: string | null,
  error?: ErrorResponse | null,
};

export type ErrorResponse = {
  __typename: "ErrorResponse",
  message?: string | null,
};

export type GFTOneUserSubscription = {
  __typename: "GFTOneUserSubscription",
  id: string,
  recipient?: string | null,
  app?: string | null,
  stage?: string | null,
  eventType?: string | null,
  resourceId?: string | null,
  channel?: string | null,
  createdOn?: number | null,
  lastModifiedOn?: number | null,
  expiresOn?: number | null,
};

export type GFTOneUINotificationCount = {
  __typename: "GFTOneUINotificationCount",
  count?: number | null,
};

export type IsMemberOfGroupRequest = {
  actorID: string,
  actorType: string,
  groupID: string,
  groupType: string,
};

export type IsMemberOfGroupResponse = {
  __typename: "IsMemberOfGroupResponse",
  isMember?: boolean | null,
  status?: string | null,
};

export type IsAuthorizedBindleLockRequest = {
  actorID: string,
  resourceID: string,
};

export type IsAuthorizedBindleLockResponse = {
  __typename: "IsAuthorizedBindleLockResponse",
  isAuthorized?: boolean | null,
  status?: string | null,
};

export type CanAccessRequest = {
  actorID: string,
  actorType: string,
  operation: string,
  stage: string,
  resourceType: string,
  resourceName: string,
};

export type CanAccessResponse = {
  __typename: "CanAccessResponse",
  isAuthorized?: boolean | null,
  status?: string | null,
};

export type CheckPermissionRequest = {
  actorID: string,
  operation?: string | null,
  stage: string,
  resourceType: string,
};

export type CheckPermissionResponse = {
  __typename: "CheckPermissionResponse",
  resourceList?:  Array<ResourceInfo | null > | null,
  status?: string | null,
};

export type ResourceInfo = {
  __typename: "ResourceInfo",
  operation?: string | null,
  namespace?: string | null,
  resourceType?: string | null,
  resourceName?: string | null,
};

export type CheckPermissionBatchRequest = {
  actorID: string,
  stage: string,
  resourceType?: Array< string > | null,
};

export type CheckPermissionBatchResponse = {
  __typename: "CheckPermissionBatchResponse",
  resourceTypeList?:  Array<ResourceInfoBatchObject | null > | null,
  status?: string | null,
};

export type ResourceInfoBatchObject = {
  __typename: "ResourceInfoBatchObject",
  resourceType?: string | null,
  resourceList?:  Array<ResourceInfo | null > | null,
};

export type ListMemberOfGroupRequest = {
  groupID: string,
  groupType: string,
};

export type ListMemberOfGroupResponse = {
  __typename: "ListMemberOfGroupResponse",
  listOfMembers?:  Array<MemberInfo | null > | null,
};

export type MemberInfo = {
  __typename: "MemberInfo",
  actorID?: string | null,
  actorType?: string | null,
};

export type ListBindleByOwnerRequest = {
  actorID: string,
};

export type ListBindleByOwnerResponse = {
  __typename: "ListBindleByOwnerResponse",
  listOfBindles?:  Array<BindleInfo | null > | null,
};

export type BindleInfo = {
  __typename: "BindleInfo",
  bindleID?: string | null,
  bindleName?: string | null,
};

export type CreateSIMTicketRequest = {
  title: string,
  description: string,
  service: string,
  requestor?: string | null,
};

export type CreateSIMTicketResponse = {
  __typename: "CreateSIMTicketResponse",
  ticketID?: string | null,
};

export type SandboxGroupMembership = {
  __typename: "SandboxGroupMembership",
  is_super_admin: boolean,
  member_of_groups?: Array< string | null > | null,
  sandboxes_accessible?:  Array<UserSandboxAccess | null > | null,
};

export type UserSandboxAccess = {
  __typename: "UserSandboxAccess",
  sandbox_id: string,
  sandbox_name: string,
  status?: SandboxStatus | null,
  is_active: boolean,
  created_at?: number | null,
  permissions?: SandboxAccessMap | null,
};

export type SandboxAccessMap = {
  __typename: "SandboxAccessMap",
  SIM_RUN?: boolean | null,
  SIM_UPDATE?: boolean | null,
  IS_ADMIN?: boolean | null,
};

export type isAdminResponse = {
  __typename: "isAdminResponse",
  is_admin: boolean,
};

export type TableSandboxFilterRequest = {
  sandbox_id?: TableStringFilterRequest | null,
  status?: TableStringFilterRequest | null,
  sandbox_name?: TableStringFilterRequest | null,
};

export type TableStringFilterRequest = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  size?: ModelSizeRequest | null,
};

export type ModelSizeRequest = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type SandboxListPage = {
  __typename: "SandboxListPage",
  items?:  Array<Sandbox | null > | null,
  nextToken?: string | null,
};

export type GetAllocationConfigContentResponse = {
  __typename: "GetAllocationConfigContentResponse",
  content?: string | null,
  last_modified?: string | null,
};

export type AllocationConfigSchema = {
  __typename: "AllocationConfigSchema",
  column_schema?:  Array<AllocationConfigSchemaColumn | null > | null,
  query_schema?: string | null,
};

export type AllocationConfigSchemaColumn = {
  __typename: "AllocationConfigSchemaColumn",
  name?: string | null,
  label?: string | null,
  type?: string | null,
  required?: boolean | null,
  defaultValue?: string | null,
};

export type InputListPage = {
  __typename: "InputListPage",
  items?:  Array<UserInput | null > | null,
  nextToken?: string | null,
};

export type InputDictionaryDiff = {
  __typename: "InputDictionaryDiff",
  input_json?: string | null,
  input_schema?:  Array<AllocationConfigSchemaColumn > | null,
  diff?: string | null,
  userAlias?: string | null,
  created_at: number,
};

export type SimBySandboxIdStatusFilterRequest = {
  name?: TableStringFilterRequest | null,
  allocation_type?: TableStringFilterRequest | null,
  userAlias?: TableStringFilterRequest | null,
};

export type SimListPage = {
  __typename: "SimListPage",
  items?:  Array<Sim | null > | null,
  nextToken?: string | null,
};

export type Report = {
  __typename: "Report",
  sandbox_id: string,
  resource_key: string,
  status_key: string,
  resource_id: string,
  resource_type: ResourceType,
  is_active: boolean,
  status: ReportStatus,
  created_at: number,
  sim_id: string,
};

export enum ReportStatus {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
}


export type SyncTimingResponse = {
  __typename: "SyncTimingResponse",
  allocationDefinitionSyncTime?: number | null,
  allocationMappingSyncTime?: number | null,
};

export type GetConfigurationsResponse = {
  __typename: "GetConfigurationsResponse",
  content?: string | null,
};

export type TableScenarioFilterRequest = {
  scenario_name?: TableStringFilterRequest | null,
  status?: TableStringFilterRequest | null,
  start_period?: TableStringFilterRequest | null,
  end_period?: TableStringFilterRequest | null,
};

export type ScenarioListPage = {
  __typename: "ScenarioListPage",
  items?:  Array<Scenario | null > | null,
  nextToken?: string | null,
};

export type GetSchemaRequest = {
  inputName: string,
  user: string,
};

export type GetSchemaResponse = {
  __typename: "GetSchemaResponse",
  attributeList?:  Array<AttributeSchema | null > | null,
};

export type AttributeSchema = {
  __typename: "AttributeSchema",
  attributeName?: string | null,
  attributeLabel?: string | null,
  attributeType?: string | null,
  isRequired?: boolean | null,
  isDisabled?: boolean | null,
  isHidden?: boolean | null,
  defaultValue?: string | null,
  description?: string | null,
  options?: Array< string | null > | null,
};

export type CreateReviewMutationVariables = {
  input: CreateReviewInput,
};

export type CreateReviewMutation = {
  createReview?:  {
    __typename: "StatusOutput",
    status?: string | null,
  } | null,
};

export type CreateSystemMutationVariables = {
  input: CreateSystemInput,
};

export type CreateSystemMutation = {
  createSystem?:  {
    __typename: "StatusOutput",
    status?: string | null,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "StatusOutput",
    status?: string | null,
  } | null,
};

export type AddCommentMutationVariables = {
  input: AddCommentInput,
};

export type AddCommentMutation = {
  addComment?:  {
    __typename: "StatusOutput",
    status?: string | null,
  } | null,
};

export type ManageReviewMutationVariables = {
  input: ManageReviewRequest,
};

export type ManageReviewMutation = {
  manageReview?:  {
    __typename: "StatusOutput",
    status?: string | null,
  } | null,
};

export type ManageReviewerReviewMutationVariables = {
  input: ManageReviewerReviewRequest,
};

export type ManageReviewerReviewMutation = {
  manageReviewerReview?:  {
    __typename: "ManageReviewerReviewResponse",
    status?: string | null,
    review?:  {
      __typename: "ReviewInfo",
      reviewId?: string | null,
      reviewStatus?: ReviewStatus | null,
      reviewJson?: string | null,
      system?: string | null,
      timestamp?: string | null,
      requesterId?: string | null,
      resourceIdentifier?: string | null,
      reviewers?: Array< string | null > | null,
      reviewDescription?: string | null,
      reviewedTimestamp?: string | null,
    } | null,
    reviewStatus?: string | null,
    configStatus?: string | null,
  } | null,
};

export type CreateConfigurationMutationVariables = {
  input: CreateConfigWithReviewInput,
};

export type CreateConfigurationMutation = {
  createConfiguration?:  {
    __typename: "CreateConfigWithReviewOutput",
    reviewStatus?: string | null,
    status?: string | null,
  } | null,
};

export type CreateVersionMutationVariables = {
  input: CreateVersionWithReviewInput,
};

export type CreateVersionMutation = {
  createVersion?:  {
    __typename: "CreateVersionWithReviewOutput",
    reviewStatus?: string | null,
    status?: string | null,
  } | null,
};

export type CreateConfigurationInDataStoreMutationVariables = {
  input: CreateConfigurationRequest,
};

export type CreateConfigurationInDataStoreMutation = {
  createConfigurationInDataStore?:  {
    __typename: "CreateConfigurationResponse",
    string?: string | null,
  } | null,
};

export type CreateVersionInDataStoreMutationVariables = {
  input: CreateConfigurationRequest,
};

export type CreateVersionInDataStoreMutation = {
  createVersionInDataStore?:  {
    __typename: "CreateConfigurationResponse",
    string?: string | null,
  } | null,
};

export type CreateTenantMutationVariables = {
  input: CreateTenantRequest,
};

export type CreateTenantMutation = {
  createTenant?:  {
    __typename: "CreateConfigurationResponse",
    string?: string | null,
  } | null,
};

export type CreateGFTOneNotificationRequestMutationVariables = {
  input: CreateGFTOneNotificationRequestInput,
};

export type CreateGFTOneNotificationRequestMutation = {
  createGFTOneNotificationRequest?:  {
    __typename: "CreateGFTOneNotificationRequestOutPut",
    allNotifications?:  Array< {
      __typename: "eachNotification",
      id?: string | null,
      app?: string | null,
      channel?: string | null,
      stage?: string | null,
      recipients?: Array< string | null > | null,
      notificationType?: NotificationType | null,
      isSplitGroup?: boolean | null,
      sender?: string | null,
    } | null > | null,
  } | null,
};

export type CreateGFTOneUserSubscriptionMutationVariables = {
  input: CreateGFTOneUserSubscriptionRequest,
};

export type CreateGFTOneUserSubscriptionMutation = {
  createGFTOneUserSubscription?:  {
    __typename: "CreateGFTOneUserSubscriptionResponse",
    id: string,
    status?: string | null,
    app?: string | null,
  } | null,
};

export type DeleteGFTOneUserSubscriptionMutationVariables = {
  input: DeleteGFTOneUserSubscriptionRequest,
};

export type DeleteGFTOneUserSubscriptionMutation = {
  deleteGFTOneUserSubscription?:  {
    __typename: "DeleteGFTOneUserSubscriptionResponse",
    id: string,
    status?: string | null,
  } | null,
};

export type UpdateGFTOneUserSubscriptionMutationVariables = {
  input: UpdateGFTOneUserSubscriptionRequest,
};

export type UpdateGFTOneUserSubscriptionMutation = {
  updateGFTOneUserSubscription?:  {
    __typename: "UpdateGFTOneUserSubscriptionResponse",
    id: string,
    status?: string | null,
  } | null,
};

export type UpdateGFTOneUINotificationMutationVariables = {
  input: UpdateGFTOneUINotificationRequest,
};

export type UpdateGFTOneUINotificationMutation = {
  updateGFTOneUINotification?:  {
    __typename: "UpdateGFTOneUINotificationResponse",
    id: string,
    status?: string | null,
  } | null,
};

export type GetAllGFTOneUINotificationsMutationVariables = {
  input?: GFTOneNotificationsInput | null,
};

export type GetAllGFTOneUINotificationsMutation = {
  getAllGFTOneUINotifications?:  {
    __typename: "getListOfNotifications",
    items?:  Array< {
      __typename: "UINotification",
      id?: string | null,
      app?: string | null,
      channel?: string | null,
      alias?: string | null,
      created_at?: string | null,
      eventType?: string | null,
      stageSystemKey?: string | null,
      seen?: boolean | null,
      read?: boolean | null,
      sender?: string | null,
    } | null > | null,
  } | null,
};

export type CreateResourceMutationVariables = {
  input: CreateBindleResourceRequest,
};

export type CreateResourceMutation = {
  createResource?:  {
    __typename: "CreateBindleResourceResponse",
    resourceID?: string | null,
    status?: string | null,
  } | null,
};

export type CreateSandboxMutationVariables = {
  userAlias: string,
  request: CreateSandboxRequest,
};

export type CreateSandboxMutation = {
  // Only ACTIVE resources can be updated
  // Only ACTIVE resources can be deprecated
  // Only ACTIVE resources can be disabled
  // Only ACTIVE resources can be enabled
  // Only ACTIVE resources can be shared
  // Only INACTIVE resources can be revived
  // Shared resources cannot be updated
  // Shared resources can be deprecated
  // Shared resources can be revived
  // Re-Sharing resources overrides all changes by destination sandboxes
  // Only COMPLETED simulations can be shared
  CreateSandbox?:  {
    __typename: "Sandbox",
    sandbox_id: string,
    resource_key: string,
    status_key?: string | null,
    resource_id?: string | null,
    resource_type?: ResourceType | null,
    is_active?: boolean | null,
    created_at?: number | null,
    status?: SandboxStatus | null,
    sandbox_name?: string | null,
    allocation_types?: Array< AllocationType | null > | null,
  } | null,
};

export type UpdateSandboxMutationVariables = {
  userAlias: string,
  sandbox_id: string,
  request: UpdateSandboxRequest,
};

export type UpdateSandboxMutation = {
  UpdateSandbox?:  {
    __typename: "Sandbox",
    sandbox_id: string,
    resource_key: string,
    status_key?: string | null,
    resource_id?: string | null,
    resource_type?: ResourceType | null,
    is_active?: boolean | null,
    created_at?: number | null,
    status?: SandboxStatus | null,
    sandbox_name?: string | null,
    allocation_types?: Array< AllocationType | null > | null,
  } | null,
};

export type DeprecateSandboxMutationVariables = {
  userAlias: string,
  sandbox_id: string,
};

export type DeprecateSandboxMutation = {
  DeprecateSandbox?:  {
    __typename: "Sandbox",
    sandbox_id: string,
    resource_key: string,
    status_key?: string | null,
    resource_id?: string | null,
    resource_type?: ResourceType | null,
    is_active?: boolean | null,
    created_at?: number | null,
    status?: SandboxStatus | null,
    sandbox_name?: string | null,
    allocation_types?: Array< AllocationType | null > | null,
  } | null,
};

export type ReviveSandboxMutationVariables = {
  userAlias: string,
  sandbox_id: string,
};

export type ReviveSandboxMutation = {
  ReviveSandbox?:  {
    __typename: "Sandbox",
    sandbox_id: string,
    resource_key: string,
    status_key?: string | null,
    resource_id?: string | null,
    resource_type?: ResourceType | null,
    is_active?: boolean | null,
    created_at?: number | null,
    status?: SandboxStatus | null,
    sandbox_name?: string | null,
    allocation_types?: Array< AllocationType | null > | null,
  } | null,
};

export type DisableSandboxMutationVariables = {
  userAlias: string,
  sandbox_id: string,
};

export type DisableSandboxMutation = {
  DisableSandbox?:  {
    __typename: "Sandbox",
    sandbox_id: string,
    resource_key: string,
    status_key?: string | null,
    resource_id?: string | null,
    resource_type?: ResourceType | null,
    is_active?: boolean | null,
    created_at?: number | null,
    status?: SandboxStatus | null,
    sandbox_name?: string | null,
    allocation_types?: Array< AllocationType | null > | null,
  } | null,
};

export type EnableSandboxMutationVariables = {
  userAlias: string,
  sandbox_id: string,
};

export type EnableSandboxMutation = {
  EnableSandbox?:  {
    __typename: "Sandbox",
    sandbox_id: string,
    resource_key: string,
    status_key?: string | null,
    resource_id?: string | null,
    resource_type?: ResourceType | null,
    is_active?: boolean | null,
    created_at?: number | null,
    status?: SandboxStatus | null,
    sandbox_name?: string | null,
    allocation_types?: Array< AllocationType | null > | null,
  } | null,
};

export type UpdateInputMetadataMutationVariables = {
  s3_path: string,
};

export type UpdateInputMetadataMutation = {
  UpdateInputMetadata?:  Array< {
    __typename: "UserInput",
    sandbox_id: string,
    resource_key: string,
    status_key: string,
    resource_id: string,
    resource_type: ResourceType,
    is_active: boolean,
    created_at: number,
    status: InputStatus,
    input_type: InputType,
    allocation_type: AllocationType,
    s3_path: string,
    userAlias?: string | null,
    dictionary_input_id?: string | null,
  } | null > | null,
};

export type DeprecateInputMutationVariables = {
  sandbox_id: string,
  input_id: string,
};

export type DeprecateInputMutation = {
  DeprecateInput?:  {
    __typename: "UserInput",
    sandbox_id: string,
    resource_key: string,
    status_key: string,
    resource_id: string,
    resource_type: ResourceType,
    is_active: boolean,
    created_at: number,
    status: InputStatus,
    input_type: InputType,
    allocation_type: AllocationType,
    s3_path: string,
    userAlias?: string | null,
    dictionary_input_id?: string | null,
  } | null,
};

export type ReviveInputMutationVariables = {
  sandbox_id: string,
  input_id: string,
};

export type ReviveInputMutation = {
  ReviveInput?:  {
    __typename: "UserInput",
    sandbox_id: string,
    resource_key: string,
    status_key: string,
    resource_id: string,
    resource_type: ResourceType,
    is_active: boolean,
    created_at: number,
    status: InputStatus,
    input_type: InputType,
    allocation_type: AllocationType,
    s3_path: string,
    userAlias?: string | null,
    dictionary_input_id?: string | null,
  } | null,
};

export type CreateLinkInputMutationVariables = {
  userAlias: string,
  s3_path: string,
  sim_id?: string | null,
  sandbox_id: string,
  input_type?: InputType | null,
  name: string,
  configs: string,
};

export type CreateLinkInputMutation = {
  // Links an existing input into the simulation
  CreateLinkInput?:  {
    __typename: "Sim",
    sandbox_id: string,
    resource_key: string,
    status_key: string,
    resource_id: string,
    resource_type: ResourceType,
    is_active: boolean,
    name?: string | null,
    status: SimStatus,
    created_at: number,
    updated_at?: number | null,
    started_at?: number | null,
    finished_at?: number | null,
    error_msg?: string | null,
    // string/null if status is FAILED/otherwise
    configs?: string | null,
    allocation_type: AllocationType,
    input_ids?: Array< string | null > | null,
    mwaa_env?: string | null,
    dag_run_id?: string | null,
    scenario?: string | null,
    // Using string instead of enum to support names like "R&O Current", without having to map manually
    timeRange?: Array< string | null > | null,
    dag_tasks_status?: string | null,
    // json string `{task_id: task_state}`. TODO: convert to object representation for better control on content
    report_id?: string | null,
    is_shared?: boolean | null,
    // sharing source parameters
    shared_to_sandbox_ids?: Array< string | null > | null,
    // sharing target parameters
    shared_from_sandbox_id?: string | null,
    userAlias: string,
  } | null,
};

export type CreateSimMutationVariables = {
  sandbox_id: string,
  request: CreateSimRequest,
};

export type CreateSimMutation = {
  CreateSim?:  {
    __typename: "Sim",
    sandbox_id: string,
    resource_key: string,
    status_key: string,
    resource_id: string,
    resource_type: ResourceType,
    is_active: boolean,
    name?: string | null,
    status: SimStatus,
    created_at: number,
    updated_at?: number | null,
    started_at?: number | null,
    finished_at?: number | null,
    error_msg?: string | null,
    // string/null if status is FAILED/otherwise
    configs?: string | null,
    allocation_type: AllocationType,
    input_ids?: Array< string | null > | null,
    mwaa_env?: string | null,
    dag_run_id?: string | null,
    scenario?: string | null,
    // Using string instead of enum to support names like "R&O Current", without having to map manually
    timeRange?: Array< string | null > | null,
    dag_tasks_status?: string | null,
    // json string `{task_id: task_state}`. TODO: convert to object representation for better control on content
    report_id?: string | null,
    is_shared?: boolean | null,
    // sharing source parameters
    shared_to_sandbox_ids?: Array< string | null > | null,
    // sharing target parameters
    shared_from_sandbox_id?: string | null,
    userAlias: string,
  } | null,
};

export type StartSimMutationVariables = {
  sandbox_id: string,
  sim_id: string,
  userAlias: string,
};

export type StartSimMutation = {
  // Start ACTIVE & source sim
  StartSim?:  {
    __typename: "Sim",
    sandbox_id: string,
    resource_key: string,
    status_key: string,
    resource_id: string,
    resource_type: ResourceType,
    is_active: boolean,
    name?: string | null,
    status: SimStatus,
    created_at: number,
    updated_at?: number | null,
    started_at?: number | null,
    finished_at?: number | null,
    error_msg?: string | null,
    // string/null if status is FAILED/otherwise
    configs?: string | null,
    allocation_type: AllocationType,
    input_ids?: Array< string | null > | null,
    mwaa_env?: string | null,
    dag_run_id?: string | null,
    scenario?: string | null,
    // Using string instead of enum to support names like "R&O Current", without having to map manually
    timeRange?: Array< string | null > | null,
    dag_tasks_status?: string | null,
    // json string `{task_id: task_state}`. TODO: convert to object representation for better control on content
    report_id?: string | null,
    is_shared?: boolean | null,
    // sharing source parameters
    shared_to_sandbox_ids?: Array< string | null > | null,
    // sharing target parameters
    shared_from_sandbox_id?: string | null,
    userAlias: string,
  } | null,
};

export type ReStartSimMutationVariables = {
  sandbox_id: string,
  sim_id: string,
  userAlias: string,
};

export type ReStartSimMutation = {
  // ReStart FAILED sim
  ReStartSim?:  {
    __typename: "Sim",
    sandbox_id: string,
    resource_key: string,
    status_key: string,
    resource_id: string,
    resource_type: ResourceType,
    is_active: boolean,
    name?: string | null,
    status: SimStatus,
    created_at: number,
    updated_at?: number | null,
    started_at?: number | null,
    finished_at?: number | null,
    error_msg?: string | null,
    // string/null if status is FAILED/otherwise
    configs?: string | null,
    allocation_type: AllocationType,
    input_ids?: Array< string | null > | null,
    mwaa_env?: string | null,
    dag_run_id?: string | null,
    scenario?: string | null,
    // Using string instead of enum to support names like "R&O Current", without having to map manually
    timeRange?: Array< string | null > | null,
    dag_tasks_status?: string | null,
    // json string `{task_id: task_state}`. TODO: convert to object representation for better control on content
    report_id?: string | null,
    is_shared?: boolean | null,
    // sharing source parameters
    shared_to_sandbox_ids?: Array< string | null > | null,
    // sharing target parameters
    shared_from_sandbox_id?: string | null,
    userAlias: string,
  } | null,
};

export type CancelSimMutationVariables = {
  sandbox_id: string,
  sim_id: string,
  userAlias: string,
};

export type CancelSimMutation = {
  CancelSim?:  {
    __typename: "Sim",
    sandbox_id: string,
    resource_key: string,
    status_key: string,
    resource_id: string,
    resource_type: ResourceType,
    is_active: boolean,
    name?: string | null,
    status: SimStatus,
    created_at: number,
    updated_at?: number | null,
    started_at?: number | null,
    finished_at?: number | null,
    error_msg?: string | null,
    // string/null if status is FAILED/otherwise
    configs?: string | null,
    allocation_type: AllocationType,
    input_ids?: Array< string | null > | null,
    mwaa_env?: string | null,
    dag_run_id?: string | null,
    scenario?: string | null,
    // Using string instead of enum to support names like "R&O Current", without having to map manually
    timeRange?: Array< string | null > | null,
    dag_tasks_status?: string | null,
    // json string `{task_id: task_state}`. TODO: convert to object representation for better control on content
    report_id?: string | null,
    is_shared?: boolean | null,
    // sharing source parameters
    shared_to_sandbox_ids?: Array< string | null > | null,
    // sharing target parameters
    shared_from_sandbox_id?: string | null,
    userAlias: string,
  } | null,
};

export type DeprecateSimMutationVariables = {
  sandbox_id: string,
  sim_id: string,
  is_shared?: boolean | null,
  userAlias: string,
};

export type DeprecateSimMutation = {
  // Deprecate both source & target Sim
  DeprecateSim?:  {
    __typename: "Sim",
    sandbox_id: string,
    resource_key: string,
    status_key: string,
    resource_id: string,
    resource_type: ResourceType,
    is_active: boolean,
    name?: string | null,
    status: SimStatus,
    created_at: number,
    updated_at?: number | null,
    started_at?: number | null,
    finished_at?: number | null,
    error_msg?: string | null,
    // string/null if status is FAILED/otherwise
    configs?: string | null,
    allocation_type: AllocationType,
    input_ids?: Array< string | null > | null,
    mwaa_env?: string | null,
    dag_run_id?: string | null,
    scenario?: string | null,
    // Using string instead of enum to support names like "R&O Current", without having to map manually
    timeRange?: Array< string | null > | null,
    dag_tasks_status?: string | null,
    // json string `{task_id: task_state}`. TODO: convert to object representation for better control on content
    report_id?: string | null,
    is_shared?: boolean | null,
    // sharing source parameters
    shared_to_sandbox_ids?: Array< string | null > | null,
    // sharing target parameters
    shared_from_sandbox_id?: string | null,
    userAlias: string,
  } | null,
};

export type ReviveSimMutationVariables = {
  sandbox_id: string,
  sim_id: string,
  is_shared?: boolean | null,
  userAlias: string,
};

export type ReviveSimMutation = {
  // Revive both source & target Sim
  ReviveSim?:  {
    __typename: "Sim",
    sandbox_id: string,
    resource_key: string,
    status_key: string,
    resource_id: string,
    resource_type: ResourceType,
    is_active: boolean,
    name?: string | null,
    status: SimStatus,
    created_at: number,
    updated_at?: number | null,
    started_at?: number | null,
    finished_at?: number | null,
    error_msg?: string | null,
    // string/null if status is FAILED/otherwise
    configs?: string | null,
    allocation_type: AllocationType,
    input_ids?: Array< string | null > | null,
    mwaa_env?: string | null,
    dag_run_id?: string | null,
    scenario?: string | null,
    // Using string instead of enum to support names like "R&O Current", without having to map manually
    timeRange?: Array< string | null > | null,
    dag_tasks_status?: string | null,
    // json string `{task_id: task_state}`. TODO: convert to object representation for better control on content
    report_id?: string | null,
    is_shared?: boolean | null,
    // sharing source parameters
    shared_to_sandbox_ids?: Array< string | null > | null,
    // sharing target parameters
    shared_from_sandbox_id?: string | null,
    userAlias: string,
  } | null,
};

export type UpdateSimMutationVariables = {
  sandbox_id: string,
  sim_id: string,
  request: UpdateSimRequest,
};

export type UpdateSimMutation = {
  // Update only source Sim
  UpdateSim?:  {
    __typename: "Sim",
    sandbox_id: string,
    resource_key: string,
    status_key: string,
    resource_id: string,
    resource_type: ResourceType,
    is_active: boolean,
    name?: string | null,
    status: SimStatus,
    created_at: number,
    updated_at?: number | null,
    started_at?: number | null,
    finished_at?: number | null,
    error_msg?: string | null,
    // string/null if status is FAILED/otherwise
    configs?: string | null,
    allocation_type: AllocationType,
    input_ids?: Array< string | null > | null,
    mwaa_env?: string | null,
    dag_run_id?: string | null,
    scenario?: string | null,
    // Using string instead of enum to support names like "R&O Current", without having to map manually
    timeRange?: Array< string | null > | null,
    dag_tasks_status?: string | null,
    // json string `{task_id: task_state}`. TODO: convert to object representation for better control on content
    report_id?: string | null,
    is_shared?: boolean | null,
    // sharing source parameters
    shared_to_sandbox_ids?: Array< string | null > | null,
    // sharing target parameters
    shared_from_sandbox_id?: string | null,
    userAlias: string,
  } | null,
};

export type UpdateSimStatusMutationVariables = {
  sandbox_id: string,
  sim_id: string,
  request: UpdateSimStatusRequest,
};

export type UpdateSimStatusMutation = {
  // Update only source Sim
  UpdateSimStatus?:  {
    __typename: "Sim",
    sandbox_id: string,
    resource_key: string,
    status_key: string,
    resource_id: string,
    resource_type: ResourceType,
    is_active: boolean,
    name?: string | null,
    status: SimStatus,
    created_at: number,
    updated_at?: number | null,
    started_at?: number | null,
    finished_at?: number | null,
    error_msg?: string | null,
    // string/null if status is FAILED/otherwise
    configs?: string | null,
    allocation_type: AllocationType,
    input_ids?: Array< string | null > | null,
    mwaa_env?: string | null,
    dag_run_id?: string | null,
    scenario?: string | null,
    // Using string instead of enum to support names like "R&O Current", without having to map manually
    timeRange?: Array< string | null > | null,
    dag_tasks_status?: string | null,
    // json string `{task_id: task_state}`. TODO: convert to object representation for better control on content
    report_id?: string | null,
    is_shared?: boolean | null,
    // sharing source parameters
    shared_to_sandbox_ids?: Array< string | null > | null,
    // sharing target parameters
    shared_from_sandbox_id?: string | null,
    userAlias: string,
  } | null,
};

export type ShareSimMutationVariables = {
  sandbox_id: string,
  sim_id: string,
  request: ShareSimRequest,
};

export type ShareSimMutation = {
  // return target Sim object
  ShareSim?: string | null,
};

export type UnshareSimFromSandboxMutationVariables = {
  sandbox_id: string,
  sim_id: string,
  request: ShareSimRequest,
};

export type UnshareSimFromSandboxMutation = {
  // ALL as share_to_sandbox_id means unshare from all
  UnshareSimFromSandbox?: string | null,
};

export type UpdateConfigurationMutationVariables = {
  userAlias: string,
  content: string,
  configType: ConfigType,
};

export type UpdateConfigurationMutation = {
  // Update the configuration/feature flag profile
  UpdateConfiguration?:  {
    __typename: "UpdateConfigurationResponse",
    content?: string | null,
  } | null,
};

export type CreateScenarioMutationVariables = {
  userAlias: string,
  request: CreateScenarioRequest,
};

export type CreateScenarioMutation = {
  // Create a new scenario
  CreateScenario?:  {
    __typename: "Scenario",
    scenario_name: string,
    start_period: string,
    end_period: string,
    default_public: boolean,
    created_at: number,
    status: ScenarioStatus,
    status_key: string,
    resource_key: string,
    display_period: boolean,
    resource_id: string,
    sandbox_id?: string | null,
    allocation_type?: AllocationType | null,
  } | null,
};

export type UpdateScenarioMutationVariables = {
  userAlias: string,
  request: UpdateScenarioRequest,
};

export type UpdateScenarioMutation = {
  // Update existing scenario
  UpdateScenario?:  {
    __typename: "Scenario",
    scenario_name: string,
    start_period: string,
    end_period: string,
    default_public: boolean,
    created_at: number,
    status: ScenarioStatus,
    status_key: string,
    resource_key: string,
    display_period: boolean,
    resource_id: string,
    sandbox_id?: string | null,
    allocation_type?: AllocationType | null,
  } | null,
};

export type EnableScenarioMutationVariables = {
  userAlias: string,
  request: EnableScenarioRequest,
};

export type EnableScenarioMutation = {
  EnableScenario?:  {
    __typename: "Scenario",
    scenario_name: string,
    start_period: string,
    end_period: string,
    default_public: boolean,
    created_at: number,
    status: ScenarioStatus,
    status_key: string,
    resource_key: string,
    display_period: boolean,
    resource_id: string,
    sandbox_id?: string | null,
    allocation_type?: AllocationType | null,
  } | null,
};

export type DisableScenarioMutationVariables = {
  userAlias: string,
  request: DisableScenarioRequest,
};

export type DisableScenarioMutation = {
  DisableScenario?:  {
    __typename: "Scenario",
    scenario_name: string,
    start_period: string,
    end_period: string,
    default_public: boolean,
    created_at: number,
    status: ScenarioStatus,
    status_key: string,
    resource_key: string,
    display_period: boolean,
    resource_id: string,
    sandbox_id?: string | null,
    allocation_type?: AllocationType | null,
  } | null,
};

export type ValidateAndExportMutationVariables = {
  input?: ValidateAndExportRequest | null,
};

export type ValidateAndExportMutation = {
  ValidateAndExport?:  {
    __typename: "ValidateAndExportResponse",
    errors?:  Array< {
      __typename: "ErrorDetail",
      rowNumber?: number | null,
      attributeName?: string | null,
      attributeValue?: string | null,
      errorMessage?: string | null,
      suggestion?: string | null,
    } | null > | null,
    dataTarget?:  {
      __typename: "DataTargetDetail",
      dataTargetType?: string | null,
      filePath?: string | null,
    } | null,
    status?: ExportStatus | null,
    message?: string | null,
  } | null,
};

export type FetchReviewsQueryVariables = {
  input: FetchReviewsRequest,
};

export type FetchReviewsQuery = {
  fetchReviews?:  {
    __typename: "FetchReviewsResponse",
    reviews?:  Array< {
      __typename: "ReviewInfo",
      reviewId?: string | null,
      reviewStatus?: ReviewStatus | null,
      reviewJson?: string | null,
      system?: string | null,
      timestamp?: string | null,
      requesterId?: string | null,
      resourceIdentifier?: string | null,
      reviewers?: Array< string | null > | null,
      reviewDescription?: string | null,
      reviewedTimestamp?: string | null,
    } | null > | null,
  } | null,
};

export type FetchReviewsByUserQueryVariables = {
  input: FetchReviewsByUserRequest,
};

export type FetchReviewsByUserQuery = {
  fetchReviewsByUser?:  {
    __typename: "FetchReviewsByUserResponse",
    reviewsRequestedByUser?:  Array< {
      __typename: "ReviewInfo",
      reviewId?: string | null,
      reviewStatus?: ReviewStatus | null,
      reviewJson?: string | null,
      system?: string | null,
      timestamp?: string | null,
      requesterId?: string | null,
      resourceIdentifier?: string | null,
      reviewers?: Array< string | null > | null,
      reviewDescription?: string | null,
      reviewedTimestamp?: string | null,
    } | null > | null,
    reviewsToBeReviewedByUser?:  Array< {
      __typename: "ReviewInfo",
      reviewId?: string | null,
      reviewStatus?: ReviewStatus | null,
      reviewJson?: string | null,
      system?: string | null,
      timestamp?: string | null,
      requesterId?: string | null,
      resourceIdentifier?: string | null,
      reviewers?: Array< string | null > | null,
      reviewDescription?: string | null,
      reviewedTimestamp?: string | null,
    } | null > | null,
  } | null,
};

export type CheckSystemPermissionQueryVariables = {
  input: CheckSystemPermissionInput,
};

export type CheckSystemPermissionQuery = {
  checkSystemPermission?:  {
    __typename: "CheckSystemPermissionOutput",
    hasAccess?: boolean | null,
  } | null,
};

export type FetchReviewByIdQueryVariables = {
  input: FetchReviewByIdRequest,
};

export type FetchReviewByIdQuery = {
  fetchReviewById?:  {
    __typename: "FetchReviewByIdResponse",
    review?:  {
      __typename: "ReviewInfo",
      reviewId?: string | null,
      reviewStatus?: ReviewStatus | null,
      reviewJson?: string | null,
      system?: string | null,
      timestamp?: string | null,
      requesterId?: string | null,
      resourceIdentifier?: string | null,
      reviewers?: Array< string | null > | null,
      reviewDescription?: string | null,
      reviewedTimestamp?: string | null,
    } | null,
  } | null,
};

export type FetchAccessibleReviewsQueryVariables = {
  input?: FetchReviewsRequest | null,
};

export type FetchAccessibleReviewsQuery = {
  fetchAccessibleReviews?:  {
    __typename: "FetchReviewsResponse",
    reviews?:  Array< {
      __typename: "ReviewInfo",
      reviewId?: string | null,
      reviewStatus?: ReviewStatus | null,
      reviewJson?: string | null,
      system?: string | null,
      timestamp?: string | null,
      requesterId?: string | null,
      resourceIdentifier?: string | null,
      reviewers?: Array< string | null > | null,
      reviewDescription?: string | null,
      reviewedTimestamp?: string | null,
    } | null > | null,
  } | null,
};

export type GetConfigurationQueryVariables = {
  input: GetConfigurationRequest,
};

export type GetConfigurationQuery = {
  getConfiguration?:  {
    __typename: "GetConfigurationResponse",
    configs?: string | null,
    metadata?: string | null,
    user?: string | null,
  } | null,
};

export type GetListOfTenantsQueryVariables = {
};

export type GetListOfTenantsQuery = {
  getListOfTenants?:  {
    __typename: "TenantsList",
    tenants?: Array< string > | null,
  } | null,
};

export type GetListOfConfigurationsQueryVariables = {
  input: GetListOfConfigsRequest,
};

export type GetListOfConfigurationsQuery = {
  getListOfConfigurations?:  {
    __typename: "ConfigsList",
    configs?:  Array< {
      __typename: "ConfigInfo",
      configName: string,
      configType?: ConfigType | null,
      parentConfig?: string | null,
    } | null > | null,
  } | null,
};

export type RetrieveConfigurationsQueryVariables = {
  tenant: string,
  configList?: Array< RetrieveConfigListInput | null > | null,
  inheritFlag?: boolean | null,
};

export type RetrieveConfigurationsQuery = {
  retrieveConfigurations?:  {
    __typename: "RetrieveConfigurationsResponse",
    configsMap?:  Array< {
      __typename: "ConfigMapList",
      configName?: string | null,
      parentConfig?: string | null,
      configType?: ConfigType | null,
      configParams?: string | null,
      versionDescription?: string | null,
      metadata?: string | null,
      user?: string | null,
    } | null > | null,
  } | null,
};

export type GetListOfAccessibleTenantsQueryVariables = {
  input: GetListOfAccessibleTenantsRequest,
};

export type GetListOfAccessibleTenantsQuery = {
  getListOfAccessibleTenants?:  {
    __typename: "GetListOfAccessibleTenantsResponse",
    tenants?:  Array< {
      __typename: "TenantInfo",
      tenantName?: string | null,
      operation?: string | null,
    } > | null,
  } | null,
};

export type GetSyncStatusQueryVariables = {
};

export type GetSyncStatusQuery = {
  GetSyncStatus?:  {
    __typename: "GetSyncStatusResponse",
    statusCode?: number | null,
    data?:  {
      __typename: "SyncStatus",
    } | null,
  } | null,
};

export type IsAuthorizedToAccessServiceQueryVariables = {
  input: IsAuthorizedRequest,
};

export type IsAuthorizedToAccessServiceQuery = {
  IsAuthorizedToAccessService?:  {
    __typename: "IsAuthorizedResponse",
    isAuthorized?: boolean | null,
  } | null,
};

export type GetDashboardEmbedUrlQueryVariables = {
  input: GetDashboardEmbedUrlRequest,
};

export type GetDashboardEmbedUrlQuery = {
  GetDashboardEmbedUrl?:  {
    __typename: "GetDashboardEmbedUrlResponse",
    url?: string | null,
    error?:  {
      __typename: "ErrorResponse",
      message?: string | null,
    } | null,
  } | null,
};

export type GetGFTOneUserSubscriptionQueryVariables = {
  id: string,
  name?: string | null,
};

export type GetGFTOneUserSubscriptionQuery = {
  getGFTOneUserSubscription?:  {
    __typename: "GFTOneUserSubscription",
    id: string,
    recipient?: string | null,
    app?: string | null,
    stage?: string | null,
    eventType?: string | null,
    resourceId?: string | null,
    channel?: string | null,
    createdOn?: number | null,
    lastModifiedOn?: number | null,
    expiresOn?: number | null,
  } | null,
};

export type GetGFTOneUINotificationsCountQueryVariables = {
  input?: GFTOneNotificationsInput | null,
};

export type GetGFTOneUINotificationsCountQuery = {
  getGFTOneUINotificationsCount?:  {
    __typename: "GFTOneUINotificationCount",
    count?: number | null,
  } | null,
};

export type GetGFTOneUINotificationsQueryVariables = {
  input?: GFTOneNotificationsInput | null,
};

export type GetGFTOneUINotificationsQuery = {
  getGFTOneUINotifications?:  {
    __typename: "getListOfNotifications",
    items?:  Array< {
      __typename: "UINotification",
      id?: string | null,
      app?: string | null,
      channel?: string | null,
      alias?: string | null,
      created_at?: string | null,
      eventType?: string | null,
      stageSystemKey?: string | null,
      seen?: boolean | null,
      read?: boolean | null,
      sender?: string | null,
    } | null > | null,
  } | null,
};

export type IsMemberOfGroupQueryVariables = {
  input: IsMemberOfGroupRequest,
};

export type IsMemberOfGroupQuery = {
  isMemberOfGroup?:  {
    __typename: "IsMemberOfGroupResponse",
    isMember?: boolean | null,
    status?: string | null,
  } | null,
};

export type IsAuthorizedQueryVariables = {
  input: IsAuthorizedBindleLockRequest,
};

export type IsAuthorizedQuery = {
  isAuthorized?:  {
    __typename: "IsAuthorizedBindleLockResponse",
    isAuthorized?: boolean | null,
    status?: string | null,
  } | null,
};

export type CanAccessQueryVariables = {
  input: CanAccessRequest,
};

export type CanAccessQuery = {
  canAccess?:  {
    __typename: "CanAccessResponse",
    isAuthorized?: boolean | null,
    status?: string | null,
  } | null,
};

export type CheckPermissionQueryVariables = {
  input: CheckPermissionRequest,
};

export type CheckPermissionQuery = {
  checkPermission?:  {
    __typename: "CheckPermissionResponse",
    resourceList?:  Array< {
      __typename: "ResourceInfo",
      operation?: string | null,
      namespace?: string | null,
      resourceType?: string | null,
      resourceName?: string | null,
    } | null > | null,
    status?: string | null,
  } | null,
};

export type CheckPermissionBatchQueryVariables = {
  input: CheckPermissionBatchRequest,
};

export type CheckPermissionBatchQuery = {
  checkPermissionBatch?:  {
    __typename: "CheckPermissionBatchResponse",
    resourceTypeList?:  Array< {
      __typename: "ResourceInfoBatchObject",
      resourceType?: string | null,
    } | null > | null,
    status?: string | null,
  } | null,
};

export type ListMemberOfGroupQueryVariables = {
  input: ListMemberOfGroupRequest,
};

export type ListMemberOfGroupQuery = {
  listMemberOfGroup?:  {
    __typename: "ListMemberOfGroupResponse",
    listOfMembers?:  Array< {
      __typename: "MemberInfo",
      actorID?: string | null,
      actorType?: string | null,
    } | null > | null,
  } | null,
};

export type ListBindleByOwnerQueryVariables = {
  input: ListBindleByOwnerRequest,
};

export type ListBindleByOwnerQuery = {
  listBindleByOwner?:  {
    __typename: "ListBindleByOwnerResponse",
    listOfBindles?:  Array< {
      __typename: "BindleInfo",
      bindleID?: string | null,
      bindleName?: string | null,
    } | null > | null,
  } | null,
};

export type CreateSIMTicketQueryVariables = {
  input: CreateSIMTicketRequest,
};

export type CreateSIMTicketQuery = {
  createSIMTicket?:  {
    __typename: "CreateSIMTicketResponse",
    ticketID?: string | null,
  } | null,
};

export type GetGroupsUserIsMemberOfQueryVariables = {
  userAlias: string,
};

export type GetGroupsUserIsMemberOfQuery = {
  GetGroupsUserIsMemberOf?:  {
    __typename: "SandboxGroupMembership",
    is_super_admin: boolean,
    member_of_groups?: Array< string | null > | null,
    sandboxes_accessible?:  Array< {
      __typename: "UserSandboxAccess",
      sandbox_id: string,
      sandbox_name: string,
      status?: SandboxStatus | null,
      is_active: boolean,
      created_at?: number | null,
    } | null > | null,
  } | null,
};

export type ListSandboxWithAclQueryVariables = {
  userAlias: string,
};

export type ListSandboxWithAclQuery = {
  ListSandboxWithAcl?:  {
    __typename: "SandboxGroupMembership",
    is_super_admin: boolean,
    member_of_groups?: Array< string | null > | null,
    sandboxes_accessible?:  Array< {
      __typename: "UserSandboxAccess",
      sandbox_id: string,
      sandbox_name: string,
      status?: SandboxStatus | null,
      is_active: boolean,
      created_at?: number | null,
    } | null > | null,
  } | null,
};

export type CheckAdminRoleQueryVariables = {
  userAlias: string,
};

export type CheckAdminRoleQuery = {
  // Returns whether a user has admin role or not
  CheckAdminRole?:  {
    __typename: "isAdminResponse",
    is_admin: boolean,
  } | null,
};

export type GetSandboxQueryVariables = {
  userAlias: string,
  sandbox_id: string,
  is_active?: boolean | null,
};

export type GetSandboxQuery = {
  GetSandbox?:  {
    __typename: "Sandbox",
    sandbox_id: string,
    resource_key: string,
    status_key?: string | null,
    resource_id?: string | null,
    resource_type?: ResourceType | null,
    is_active?: boolean | null,
    created_at?: number | null,
    status?: SandboxStatus | null,
    sandbox_name?: string | null,
    allocation_types?: Array< AllocationType | null > | null,
  } | null,
};

export type ListSandboxesQueryVariables = {
  userAlias: string,
  is_active?: boolean | null,
  filter?: TableSandboxFilterRequest | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSandboxesQuery = {
  ListSandboxes?:  {
    __typename: "SandboxListPage",
    items?:  Array< {
      __typename: "Sandbox",
      sandbox_id: string,
      resource_key: string,
      status_key?: string | null,
      resource_id?: string | null,
      resource_type?: ResourceType | null,
      is_active?: boolean | null,
      created_at?: number | null,
      status?: SandboxStatus | null,
      sandbox_name?: string | null,
      allocation_types?: Array< AllocationType | null > | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetInputContentQueryVariables = {
  sandbox_id: string,
  input_id: string,
  allocation_pool_filters?: Array< string | null > | null,
  limit?: number | null,
  offset?: number | null,
};

export type GetInputContentQuery = {
  GetInputContent?:  {
    __typename: "GetAllocationConfigContentResponse",
    content?: string | null,
    last_modified?: string | null,
  } | null,
};

export type GetInputSchemaQueryVariables = {
  allocation_type: AllocationType,
  input_type: InputType,
  sandbox_id?: string | null,
  input_id?: string | null,
};

export type GetInputSchemaQuery = {
  // if sandbox_id and input_id are provided, return schema when input is created
  GetInputSchema?:  {
    __typename: "AllocationConfigSchema",
    column_schema?:  Array< {
      __typename: "AllocationConfigSchemaColumn",
      name?: string | null,
      label?: string | null,
      type?: string | null,
      required?: boolean | null,
      defaultValue?: string | null,
    } | null > | null,
    query_schema?: string | null,
  } | null,
};

export type ListInputQueryVariables = {
  sandbox_id: string,
  is_active?: boolean | null,
  allocation_type?: AllocationType | null,
  input_type?: InputType | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInputQuery = {
  ListInput?:  {
    __typename: "InputListPage",
    items?:  Array< {
      __typename: "UserInput",
      sandbox_id: string,
      resource_key: string,
      status_key: string,
      resource_id: string,
      resource_type: ResourceType,
      is_active: boolean,
      created_at: number,
      status: InputStatus,
      input_type: InputType,
      allocation_type: AllocationType,
      s3_path: string,
      userAlias?: string | null,
      dictionary_input_id?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetAllocationConfigContentQueryVariables = {
  allocation_type: AllocationType,
  input_type: InputType,
  sandbox_id?: string | null,
  input_id?: string | null,
  allocation_pool_filters?: Array< string | null > | null,
  limit?: number | null,
  offset?: number | null,
};

export type GetAllocationConfigContentQuery = {
  // if sandbox_id and input_id are provided, return content when input is created
  GetAllocationConfigContent?:  {
    __typename: "GetAllocationConfigContentResponse",
    content?: string | null,
    last_modified?: string | null,
  } | null,
};

export type GetAllocationConfigSchemaQueryVariables = {
  allocation_type: AllocationType,
  input_type: InputType,
  sandbox_id?: string | null,
  input_id?: string | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetAllocationConfigSchemaQuery = {
  // if sandbox_id and input id are provided, return schema when input is created
  GetAllocationConfigSchema?:  {
    __typename: "AllocationConfigSchema",
    column_schema?:  Array< {
      __typename: "AllocationConfigSchemaColumn",
      name?: string | null,
      label?: string | null,
      type?: string | null,
      required?: boolean | null,
      defaultValue?: string | null,
    } | null > | null,
    query_schema?: string | null,
  } | null,
};

export type ListInputDictionaryDiffQueryVariables = {
  sandbox_id?: string | null,
  input_id?: string | null,
};

export type ListInputDictionaryDiffQuery = {
  ListInputDictionaryDiff?:  {
    __typename: "InputDictionaryDiff",
    input_json?: string | null,
    input_schema?:  Array< {
      __typename: "AllocationConfigSchemaColumn",
      name?: string | null,
      label?: string | null,
      type?: string | null,
      required?: boolean | null,
      defaultValue?: string | null,
    } > | null,
    diff?: string | null,
    userAlias?: string | null,
    created_at: number,
  } | null,
};

export type GetSimDetailsQueryVariables = {
  sandbox_id: string,
  sim_id: string,
  is_active?: boolean | null,
  is_shared?: boolean | null,
};

export type GetSimDetailsQuery = {
  GetSimDetails?:  {
    __typename: "Sim",
    sandbox_id: string,
    resource_key: string,
    status_key: string,
    resource_id: string,
    resource_type: ResourceType,
    is_active: boolean,
    name?: string | null,
    status: SimStatus,
    created_at: number,
    updated_at?: number | null,
    started_at?: number | null,
    finished_at?: number | null,
    error_msg?: string | null,
    // string/null if status is FAILED/otherwise
    configs?: string | null,
    allocation_type: AllocationType,
    input_ids?: Array< string | null > | null,
    mwaa_env?: string | null,
    dag_run_id?: string | null,
    scenario?: string | null,
    // Using string instead of enum to support names like "R&O Current", without having to map manually
    timeRange?: Array< string | null > | null,
    dag_tasks_status?: string | null,
    // json string `{task_id: task_state}`. TODO: convert to object representation for better control on content
    report_id?: string | null,
    is_shared?: boolean | null,
    // sharing source parameters
    shared_to_sandbox_ids?: Array< string | null > | null,
    // sharing target parameters
    shared_from_sandbox_id?: string | null,
    userAlias: string,
  } | null,
};

export type ListSimsBySandboxQueryVariables = {
  sandbox_id: string,
  is_active?: boolean | null,
  is_shared?: boolean | null,
  filter?: SimBySandboxIdStatusFilterRequest | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSimsBySandboxQuery = {
  // List target/source/any sims
  ListSimsBySandbox?:  {
    __typename: "SimListPage",
    items?:  Array< {
      __typename: "Sim",
      sandbox_id: string,
      resource_key: string,
      status_key: string,
      resource_id: string,
      resource_type: ResourceType,
      is_active: boolean,
      name?: string | null,
      status: SimStatus,
      created_at: number,
      updated_at?: number | null,
      started_at?: number | null,
      finished_at?: number | null,
      error_msg?: string | null,
      // string/null if status is FAILED/otherwise
      configs?: string | null,
      allocation_type: AllocationType,
      input_ids?: Array< string | null > | null,
      mwaa_env?: string | null,
      dag_run_id?: string | null,
      scenario?: string | null,
      // Using string instead of enum to support names like "R&O Current", without having to map manually
      timeRange?: Array< string | null > | null,
      dag_tasks_status?: string | null,
      // json string `{task_id: task_state}`. TODO: convert to object representation for better control on content
      report_id?: string | null,
      is_shared?: boolean | null,
      // sharing source parameters
      shared_to_sandbox_ids?: Array< string | null > | null,
      // sharing target parameters
      shared_from_sandbox_id?: string | null,
      userAlias: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListAllSimsQueryVariables = {
  is_active?: boolean | null,
  filter?: SimBySandboxIdStatusFilterRequest | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAllSimsQuery = {
  // List all sims
  ListAllSims?:  {
    __typename: "SimListPage",
    items?:  Array< {
      __typename: "Sim",
      sandbox_id: string,
      resource_key: string,
      status_key: string,
      resource_id: string,
      resource_type: ResourceType,
      is_active: boolean,
      name?: string | null,
      status: SimStatus,
      created_at: number,
      updated_at?: number | null,
      started_at?: number | null,
      finished_at?: number | null,
      error_msg?: string | null,
      // string/null if status is FAILED/otherwise
      configs?: string | null,
      allocation_type: AllocationType,
      input_ids?: Array< string | null > | null,
      mwaa_env?: string | null,
      dag_run_id?: string | null,
      scenario?: string | null,
      // Using string instead of enum to support names like "R&O Current", without having to map manually
      timeRange?: Array< string | null > | null,
      dag_tasks_status?: string | null,
      // json string `{task_id: task_state}`. TODO: convert to object representation for better control on content
      report_id?: string | null,
      is_shared?: boolean | null,
      // sharing source parameters
      shared_to_sandbox_ids?: Array< string | null > | null,
      // sharing target parameters
      shared_from_sandbox_id?: string | null,
      userAlias: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListSimsByStatusBySandboxQueryVariables = {
  sandbox_id: string,
  is_active?: boolean | null,
  is_shared?: boolean | null,
  status: SimStatus,
  filter?: SimBySandboxIdStatusFilterRequest | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSimsByStatusBySandboxQuery = {
  // List target/source/any sims
  ListSimsByStatusBySandbox?:  {
    __typename: "SimListPage",
    items?:  Array< {
      __typename: "Sim",
      sandbox_id: string,
      resource_key: string,
      status_key: string,
      resource_id: string,
      resource_type: ResourceType,
      is_active: boolean,
      name?: string | null,
      status: SimStatus,
      created_at: number,
      updated_at?: number | null,
      started_at?: number | null,
      finished_at?: number | null,
      error_msg?: string | null,
      // string/null if status is FAILED/otherwise
      configs?: string | null,
      allocation_type: AllocationType,
      input_ids?: Array< string | null > | null,
      mwaa_env?: string | null,
      dag_run_id?: string | null,
      scenario?: string | null,
      // Using string instead of enum to support names like "R&O Current", without having to map manually
      timeRange?: Array< string | null > | null,
      dag_tasks_status?: string | null,
      // json string `{task_id: task_state}`. TODO: convert to object representation for better control on content
      report_id?: string | null,
      is_shared?: boolean | null,
      // sharing source parameters
      shared_to_sandbox_ids?: Array< string | null > | null,
      // sharing target parameters
      shared_from_sandbox_id?: string | null,
      userAlias: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListSimsByStatusQueryVariables = {
  is_active?: boolean | null,
  status: SimStatus,
  filter?: SimBySandboxIdStatusFilterRequest | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSimsByStatusQuery = {
  // List source sims
  ListSimsByStatus?:  {
    __typename: "SimListPage",
    items?:  Array< {
      __typename: "Sim",
      sandbox_id: string,
      resource_key: string,
      status_key: string,
      resource_id: string,
      resource_type: ResourceType,
      is_active: boolean,
      name?: string | null,
      status: SimStatus,
      created_at: number,
      updated_at?: number | null,
      started_at?: number | null,
      finished_at?: number | null,
      error_msg?: string | null,
      // string/null if status is FAILED/otherwise
      configs?: string | null,
      allocation_type: AllocationType,
      input_ids?: Array< string | null > | null,
      mwaa_env?: string | null,
      dag_run_id?: string | null,
      scenario?: string | null,
      // Using string instead of enum to support names like "R&O Current", without having to map manually
      timeRange?: Array< string | null > | null,
      dag_tasks_status?: string | null,
      // json string `{task_id: task_state}`. TODO: convert to object representation for better control on content
      report_id?: string | null,
      is_shared?: boolean | null,
      // sharing source parameters
      shared_to_sandbox_ids?: Array< string | null > | null,
      // sharing target parameters
      shared_from_sandbox_id?: string | null,
      userAlias: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetReportDetailsQueryVariables = {
  sandbox_id?: string | null,
  report_id?: string | null,
  sim_id?: string | null,
};

export type GetReportDetailsQuery = {
  // Either report_id or sim_id should be provided
  GetReportDetails?:  {
    __typename: "Report",
    sandbox_id: string,
    resource_key: string,
    status_key: string,
    resource_id: string,
    resource_type: ResourceType,
    is_active: boolean,
    status: ReportStatus,
    created_at: number,
    sim_id: string,
  } | null,
};

export type GetReportEmbedUrlQueryVariables = {
  user_id: string,
  sim_id: string,
  allocation_type?: AllocationType | null,
};

export type GetReportEmbedUrlQuery = {
  GetReportEmbedUrl?: string | null,
};

export type GetSyncTimingsQueryVariables = {
  allocation_type?: AllocationType | null,
};

export type GetSyncTimingsQuery = {
  GetSyncTimings?:  {
    __typename: "SyncTimingResponse",
    allocationDefinitionSyncTime?: number | null,
    allocationMappingSyncTime?: number | null,
  } | null,
};

export type GetConfigurationsQueryVariables = {
  userAlias: string,
  configType?: ConfigType | null,
};

export type GetConfigurationsQuery = {
  // Get all the configurations/feature flags
  GetConfigurations?:  {
    __typename: "GetConfigurationsResponse",
    content?: string | null,
  } | null,
};

export type ListScenariosQueryVariables = {
  allocation_type?: AllocationType | null,
  is_active: boolean,
  is_enabled: boolean,
  filter?: TableScenarioFilterRequest | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListScenariosQuery = {
  ListScenarios?:  {
    __typename: "ScenarioListPage",
    items?:  Array< {
      __typename: "Scenario",
      scenario_name: string,
      start_period: string,
      end_period: string,
      default_public: boolean,
      created_at: number,
      status: ScenarioStatus,
      status_key: string,
      resource_key: string,
      display_period: boolean,
      resource_id: string,
      sandbox_id?: string | null,
      allocation_type?: AllocationType | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetSchemaQueryVariables = {
  input?: GetSchemaRequest | null,
};

export type GetSchemaQuery = {
  GetSchema?:  {
    __typename: "GetSchemaResponse",
    attributeList?:  Array< {
      __typename: "AttributeSchema",
      attributeName?: string | null,
      attributeLabel?: string | null,
      attributeType?: string | null,
      isRequired?: boolean | null,
      isDisabled?: boolean | null,
      isHidden?: boolean | null,
      defaultValue?: string | null,
      description?: string | null,
      options?: Array< string | null > | null,
    } | null > | null,
  } | null,
};
