// defs
import { GraphQLError } from "graphql";

export const formatGraphQLError = (error: GraphQLError): string => {
    return JSON.stringify(
        {
            errorType: error["errorType"] || {},
            message: error.message,
            path: error.path || "N/A",
            locations: error.locations || "N/A",
            timestamp: new Date().toISOString(),
        },
        null,
        4, // 4-space indentation
    );
};

export const formatClientError = (error: any): string => {
    return JSON.stringify(
        {
            error,
            timestamp: new Date().toISOString(),
        },
        null,
        4, // 4-space indentation
    );
};
