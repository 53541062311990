// libs
import {
    PutLogEventsCommand,
    CloudWatchLogsClient,
    CreateLogStreamCommand,
    DescribeLogStreamsCommand,
} from "@aws-sdk/client-cloudwatch-logs";

// defs
import { AwsCredentialIdentity } from "@smithy/types";

// defs
export enum LogLevelMethod {
    ERROR = "error",
    INFO = "info",
    WARN = "warn",
}

export interface ILogger {
    region: string;
    userId: string;
    logGroupName: string;
    fetchCredentials: () => Promise<AwsCredentialIdentity>;
}

class LoggerService {
    private static loggerServiceInstance: LoggerService | undefined;

    private logGroupName: string;

    private logStreamName: string;

    private cloudwatchClientInstance: CloudWatchLogsClient | undefined;

    private constructor() {}

    public static getLoggerServiceInstance = (): LoggerService => {
        if (!this.loggerServiceInstance) {
            this.loggerServiceInstance = new LoggerService();
        }

        return this.loggerServiceInstance;
    };

    // initlise the logger
    public initialiseLogger = async ({ region, userId, logGroupName, fetchCredentials }: ILogger): Promise<void> => {
        try {
            // initialise the logGroup name
            this.logGroupName = logGroupName;

            const credentials = await fetchCredentials();

            // create cloudwatch client instance
            this.cloudwatchClientInstance = new CloudWatchLogsClient({ region, credentials });

            // log stream name
            this.logStreamName = this.generateLogStreamName(logGroupName, userId);

            // check logstream exist or not.
            const describeCommand = new DescribeLogStreamsCommand({
                logGroupName,
                logStreamNamePrefix: this.logStreamName,
            });
            const response = await this.cloudwatchClientInstance.send(describeCommand);

            const isLogStreamExists = response.logStreams.some((stream) => stream.logStreamName === this.logStreamName);

            // Create a log stream (if it doesn't already exist)
            if (!isLogStreamExists) {
                const createCommand = new CreateLogStreamCommand({
                    logGroupName,
                    logStreamName: this.logStreamName,
                });
                await this.cloudwatchClientInstance.send(createCommand);
            }
        } catch (error) {
            console.error(error);
        }
    };

    // Publish logs to CloudWatch
    public publishLog = (logLevelMethod: LogLevelMethod, logMessages: Array<string>): void => {
        if (this.cloudwatchClientInstance && this.logGroupName && this.logStreamName) {
            try {
                // Publish the log message
                const timestamp = Date.now();
                const logEvents = logMessages.map((logMessage) => {
                    const message = {
                        type: logLevelMethod, // error, warning, or info
                        timestamp: new Date(timestamp).toISOString(),
                        logMessage,
                    };

                    return {
                        timestamp,
                        message: JSON.stringify(message),
                    };
                });
                const putLogEventsCommand = new PutLogEventsCommand({
                    logGroupName: this.logGroupName,
                    logStreamName: this.logStreamName,
                    logEvents: [...logEvents],
                });
                this.cloudwatchClientInstance.send(putLogEventsCommand);
            } catch (error) {
                console.error(error);
            }
        } else {
            this.publishLogOnConsole(logLevelMethod, logMessages);
        }
    };

    // publish logs on browser logs
    private publishLogOnConsole = (logLevelMethod: LogLevelMethod, logMessages: Array<string>): void => {
        logMessages.forEach((logMessage) => {
            switch (logLevelMethod) {
                case LogLevelMethod.ERROR:
                    console.log(logMessage);
                    break;
                case LogLevelMethod.WARN:
                    console.warn(logMessage);
                    break;
                case LogLevelMethod.INFO:
                    console.info(logMessage);
                    break;
            }
        });
    };

    // create logstream name
    private generateLogStreamName = (logGroup, userId): string => {
        // Get the current date in YYYY-MM-DD format
        const date = new Date().toISOString().split("T")[0];

        // Combine logGroup, userId, and date to form the log stream name
        const logStreamName = `${logGroup}-${userId}-${date}`;

        return logStreamName;
    };
}

export default LoggerService;
