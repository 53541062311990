// libs
import { GraphQLResult } from "@aws-amplify/api-graphql";

// utils
import { getClient, graphQlQueries } from "@utils/appsync";

export const fetchUserGroupAndSandboxes = async (username: string): Promise<any> => {
    if (username) {
        const { data, errors }: GraphQLResult<any> = await getClient().graphql({
            query: graphQlQueries.GetGroupsUserIsMemberOf,
            variables: {
                userAlias: username,
            },
        });
        if (errors?.length) {
            return { errors };
        }
        return data;
    }
    return {
        data: {
            GetGroupsUserIsMemberOf: {
                __typename: "SandboxGroupMembership",
                is_super_admin: false,
                sandboxes_accessible: [],
            },
        },
    };
};
