// libs
import React from "react";

// components
import { Box, Button, Grid, Typography } from "@mui/material";

// TODO : Added temporary SomethingWentWrong screen
// in case of any client side i.e. javascript error in application.
// will update this SomethingWentWrong screen when mocks recived from UX.
const SomethingWentWrong: React.FC = () => {
    const onReload = (): void => {
        if (window?.location) {
            window.location.reload();
        }
    };

    return (
        <Box margin={2}>
            <Grid container direction="column" spacing={2} alignContent="center">
                <Grid item alignSelf="center">
                    <Typography variant="h3">Something went wrong</Typography>
                </Grid>
                <Grid item alignSelf="center">
                    <Typography variant="h6">Please try to refresh the page or report an issue if it occurs again</Typography>
                </Grid>
                <Grid item alignSelf="center">
                    <Button color="primary" variant="outlined" onClick={onReload}>
                        Refresh
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default React.memo(SomethingWentWrong);
