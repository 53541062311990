// types
import { IAlert } from "../defs/Alert";
import { SEVERITY } from "../defs/Severity";

export enum ErrorEnum {
    SERVER_ERROR = "SERVER_ERROR",
    UNAUTHORIZED = "UNAUTHORIZED",
    QUICKSIGHT_ERROR = "QUICKSIGHT_ERROR",
    MISSING_SIMULATION_PARAMETERS = "MISSING_SIMULATION_PARAMETERS",
    RESOURCE_NOT_FOUND = "RESOURCE_NOT_FOUND",
    SIMULATION_ALREADY_DELETED = "SIMULATION_ALREADY_DELETED",
    SIMULATION_ALREADY_STARTED_OR_FINISHED = "SIMULATION_ALREADY_STARTED_OR_FINISHED",
    SANDBOX_LIMIT_REACHED = "SANDBOX_LIMIT_REACHED",
    SIMULATION_ALREADY_QUEUED = "SIMULATION_ALREADY_QUEUED",
    JOURNAL_ENTRIES_ERROR = "JOURNAL_ENTRIES_ERROR",
}

export const SOMETHING_WENT_WRONG_ERROR_MESSAGE = "Something went wrong";

export const ERROR_MESSAGE: { [key: string]: IAlert } = {
    [ErrorEnum.SERVER_ERROR]: {
        title: SOMETHING_WENT_WRONG_ERROR_MESSAGE,
        content: "Server could not be reached. Try again or reload.",
        severity: SEVERITY.ERROR,
    },
    [ErrorEnum.UNAUTHORIZED]: {
        // TODO: Added temporary wording, will replace it with the correct wording and permission SOPs.
        title: "Permission Required",
        content: "Permission required. Please raise a ticket.",
        severity: SEVERITY.ERROR,
    },
    [ErrorEnum.QUICKSIGHT_ERROR]: {
        title: SOMETHING_WENT_WRONG_ERROR_MESSAGE,
        content: "Loading report failed. Refresh page or try again.",
        severity: SEVERITY.ERROR,
    },
    [ErrorEnum.RESOURCE_NOT_FOUND]: {
        title: "Resource not found",
        content: "loading resource. Refresh page or try again.",
        severity: SEVERITY.ERROR,
    },
    [ErrorEnum.SIMULATION_ALREADY_DELETED]: {
        title: "Unable to delete the simulation",
        content: "Simulation is already deleted",
        severity: SEVERITY.ERROR,
    },
    [ErrorEnum.SIMULATION_ALREADY_STARTED_OR_FINISHED]: {
        title: "Unable to start the simulation",
        content: "Simulation is already already started or finished",
        severity: SEVERITY.ERROR,
    },
    [ErrorEnum.SANDBOX_LIMIT_REACHED]: {
        title: "Unable to start a new simulation",
        content: "A maximum of only 2 active simulations can run at a time.",
        severity: SEVERITY.ERROR,
    },
    [ErrorEnum.JOURNAL_ENTRIES_ERROR]: {
        title: " error(s) found",
        content: "Inputs do not match system information.",
        severity: SEVERITY.ERROR,
    },
};
