export const noop = (): void => void 0;

export const truncateString = (input: string, maxLength: number): string => {
    if (input.length > maxLength) {
        return input.substring(0, maxLength) + "...";
    } else {
        return input;
    }
};

export const isNullOrEmpty = (value: string | number): boolean => {
    return value === null || value === undefined || value === "";
};

export function measureLatency(startTime: number): number {
    const latency = Date.now() - startTime;
    return latency;
}
