// libs
import { CloudWatchClient, PutMetricDataCommand } from "@aws-sdk/client-cloudwatch";

// defs
import { AwsCredentialIdentity } from "@smithy/types";
import { MetricDatum, PutMetricDataInput } from "@aws-sdk/client-cloudwatch";

export interface IMetrics {
    region: string;
    namespace: string;
    fetchCredentials: () => Promise<AwsCredentialIdentity>;
}

class MetricsService {
    private static MetricsServiceInstance: MetricsService | undefined;

    private namespace: string;

    private cloudwatchClientInstance: CloudWatchClient | undefined;

    private constructor() {}

    public static getMetricsServiceInstance = (): MetricsService => {
        if (!this.MetricsServiceInstance) {
            this.MetricsServiceInstance = new MetricsService();
        }

        return this.MetricsServiceInstance;
    };

    // initlise the metrics
    public initialiseMetrics = async ({ namespace, region, fetchCredentials }: IMetrics): Promise<void> => {
        try {
            // initialise the namespace
            this.namespace = namespace;

            // fetch the credentials
            const credentials = await fetchCredentials();

            // create cloudwatch client instance
            this.cloudwatchClientInstance = new CloudWatchClient({ region, credentials });
        } catch (error) {
            console.error(error);
        }
    };

    // record metrics to CloudWatch
    public record = (metricsData: MetricDatum): void => {
        if (this.cloudwatchClientInstance) {
            try {
                const metricsDataInput: PutMetricDataInput = {
                    Namespace: this.namespace,
                    MetricData: [metricsData],
                };

                this.cloudwatchClientInstance.send(new PutMetricDataCommand(metricsDataInput));
            } catch (error) {
                console.error(error);
            }
        }
    };
}

export default MetricsService;
