// libs
import React, { useEffect } from "react";
import { useRouteError } from "react-router-dom";

// components
import { SomethingWentWrong } from "@components/SomethingWentWrong";

// services
import { LoggerService } from "@services/LoggerService";
import { MetricsService } from "@services/MetricsService";

// constants
import { MetricName } from "@constants/Metrics";

// defs
import { LogLevelMethod } from "@services/LoggerService";
import { StandardUnit } from "@aws-sdk/client-cloudwatch-logs";

// helpers
import { formatClientError } from "@helpers/Error";

const RouteErrorBoundary: React.FC = () => {
    const error = useRouteError(); // Get the error thrown in the route

    useEffect(() => {
        // log the error on console as well
        console.error(error);

        // publish the logs
        LoggerService.getLoggerServiceInstance().publishLog(LogLevelMethod.ERROR, [formatClientError(error)]);

        // publish the metrics
        MetricsService.getMetricsServiceInstance().record({
            MetricName: MetricName.APPLICATION_ERROR_COUNT,
            Value: 1,
            Unit: StandardUnit.Count,
            Dimensions: [
                { Name: "Service", Value: "Application" },
                { Name: "MetricType", Value: "Failure" },
            ],
        });
    }, [error]);

    return <SomethingWentWrong />;
};

export default RouteErrorBoundary;
