export const PageMetric = {
    HOME_PAGE_TRAFFIC: "HomePageTraffic",
    HOME_PAGE_LOAD_TIME: "HomePageLoadTime",
    NEW_SIMULATION_PAGE_TRAFFIC: "NewSimulationPageTraffic",
    NEW_SIMULATION_PAGE_LOAD_TIME: "NewSimulationPageLoadTime",
    SIMULATION_HISTORY_PAGE_TRAFFIC: "SimulationHistoryPageTraffic",
    SIMULATION_HISTORY_PAGE_LOAD_TIME: "SimulationHistoryPageLoadTime",
    SYSTEM_DEFINITION_PAGE_TRAFFIC: "SystemDefinitionPageTraffic",
    SYSTEM_DEFINITION_PAGE_LOAD_TIME: "SystemDefinitionPageLoadTime",
    SYSTEM_MAPPING_PAGE_TRAFFIC: "SystemMappingPageTraffic",
    SYSTEM_MAPPING_PAGE_LOAD_TIME: "SystemMappingPageLoadTime",
};

export const MetricName = {
    // simulation service
    GET_SCENARIOS_LIST_LATENCY: "GetScenariosListLatency",
    GET_SCENARIOS_LIST_ERROR_COUNT: "GetScenariosListErrorCount",
    GET_SIMULATION_LIST_LATENCY: "GetSimulationListLatency",
    GET_SIMULATION_LIST_ERROR_COUNT: "GetSimulationListErrorCount",
    GET_SIMULATION_LATENCY: "GetSimulationLatency",
    GET_SIMULATION_ERROR_COUNT: "GetSimulationErrorCount",
    CREATE_SIMULATION_LATENCY: "CreateSimulationLatency",
    CREATE_SIMULATION_ERROR_COUNT: "CreateSimulationErrorCount",
    UPDATE_SIMULATION_LATENCY: "UpdateSimulationLatency",
    UPDATE_SIMULATION_ERROR_COUNT: "UpdateSimulationErrorCount",
    SAVE_SIMULATION_LATENCY: "SaveSimulationLatency",
    SAVE_SIMULATION_ERROR_COUNT: "SaveSimulationErrorCount",
    START_SIMULATION_LATENCY: "StartSimulationLatency",
    START_SIMULATION_ERROR_COUNT: "StartSimulationErrorCount",
    CANCEL_SIMULATION_LATENCY: "CancelSimulationLatency",
    CANCEL_SIMULATION_ERROR_COUNT: "CancelSimulationErrorCount",
    DELETE_SIMULATION_LATENCY: "DeleteSimulationLatency",
    DELETE_SIMULATION_ERROR_COUNT: "DeleteSimulationErrorCount",
    DEPRECATE_SIMULATION_LATENCY: "DeprecateSimulationLatency",
    DEPRECATE_SIMULATION_ERROR_COUNT: "DeprecateSimulationErrorCount",
    CREATE_INPUT_LINK_LATENCY: "CreateInputLinkLatency",
    CREATE_INPUT_LINK_ERROR_COUNT: "CreateInputLinkErrorCount",
    GET_EMBED_URL_FOR_SIMULATION_REPORT_LATENCY: "GetEmbedUrlForSimulationReportLatency",
    GET_EMBED_URL_FOR_SIMULATION_REPORT_ERROR_COUNT: "GetEmbedUrlForSimulationReportErrorCount",

    // sandbox service
    GET_SANDBOX_LIST_LATENCY: "GetSandboxListLatency",
    GET_SANDBOX_LIST_ERROR_COUNT: "GetSandboxListErrorCount",

    // input service service
    GET_ACTIVE_INPUT_LIST_LATENCY: "GetActiveInputListLatency",
    GET_ACTIVE_INPUT_LIST_ERROR_COUNT: "GetActiveInputListErrorCount",
    GET_INPUT_CONTENT_LATENCY: "GetInputContentLatency",
    GET_INPUT_CONTENT_ERROR_COUNT: "GetInputContentErrorCount",
    GET_INPUT_DICTIONARY_DIFF_LATENCY: "GetInputDictionaryDiffLatency",
    GET_INPUT_DICTIONARY_DIFF_ERROR_COUNT: "GetInputDictionaryDiffErrorCount",

    // Data input tool service
    GET_DATA_INPUT_SCHEMA_LATENCY: "GetDataInputSchemaLatency",
    GET_DATA_INPUT_SCHEMA_ERROR_COUNT: "GetDataInputSchemaErrorCount",
    VALIDATION_AND_EXPORT_LATENCY: "ValidationAndExportLatency",
    VALIDATION_AND_EXPORT_ERROR_COUNT: "ValidationAndExportErrorCount",

    // feature flag service
    GET_FEATURE_FLAGS_LATENCY: "GetFeatureFlagsLatency",
    GET_FEATURE_FLAGS_ERROR_COUNT: "GetFeatureFlagsErrorCount",

    // Sim Ticket service
    CREATE_SIM_TICKET_LATENCY: "CreateSimTicketLatency",
    CREATE_SIM_TICKET_ERROR_COUNT: "CreateSimTicketErrorCount",

    // user service
    FETCH_USER_GROUP_AND_SANDBOX_LATENCY: "FetchUserGroupAndSandboxLatency",
    FETCH_USER_GROUP_AND_SANDBOX_ERROR_COUNT: "FetchUserGroupAndSandboxErrorCount",

    // allocation config service
    GET_ALLOCATION_CONFIG_SCHEMA_LATENCY: "GetAllocationConfigSchemaLatency",
    GET_ALLOCATION_CONFIG_SCHEMA_ERROR_COUNT: "GetAllocationConfigSchemaErrorCount",
    GET_ALLOCATION_CONFIG_CONTENT_LATENCY: "GetAllocationConfigContentLatency",
    GET_ALLOCATION_CONFIG_CONTENT_ERROR_COUNT: "GetAllocationConfigContentErrorCount",

    // application
    APPLICATION_ERROR_COUNT: "ApplicationErrErrorCount",
};
