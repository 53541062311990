// libs
import { Tuple, configureStore } from "@reduxjs/toolkit";
import { Middlewares } from "@reduxjs/toolkit/dist/configureStore";

// reducers
import loaderReducer from "@store/LoaderReducer";
import sideMenuReducer from "@store/SideMenuReducer";
import userInfoReducer from "@store/UserInfoReducer";
import pageTitleReducer from "@store/PageTitleReducer";
import pageAlertReducer from "@store/PageAlertReducer";

export const store = configureStore<any, any, Tuple<Middlewares<any>>>({
    devTools: true,
    reducer: {
        loader: loaderReducer,
        userInfo: userInfoReducer,
        sideMenu: sideMenuReducer,
        pageTitle: pageTitleReducer,
        pageAlert: pageAlertReducer,
    },
});
