export enum ServiceType {
    SimulationService = "SimulationService",
    SandboxService = "SandboxService",
    InputService = "InputService",
    SimTicketService = "SimTicketService",
    UserService = "UserService",
    FeatureFlagService = "FeatureFlagService",
    DataInputToolService = "DataInputToolService",
    AllocationConfigService = "AllocationConfigService",
}
