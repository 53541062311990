// libs
import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

// routes
import { getRouter } from "./router";

// stores
import { store } from "./store";

// components
import { LinearLoader } from "@components/Loader";

const App: React.FC = () => {
    // Create a client
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            },
        },
    });

    return (
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <Suspense fallback={<LinearLoader />}>
                    <RouterProvider router={getRouter()} />
                </Suspense>
            </QueryClientProvider>
        </Provider>
    );
};

export default App;
