/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const fetchReviews = /* GraphQL */ `query FetchReviews($input: FetchReviewsRequest!) {
  fetchReviews(input: $input) {
    reviews {
      reviewId
      reviewStatus
      reviewJson
      system
      timestamp
      requesterId
      resourceIdentifier
      reviewers
      reviewDescription
      reviewedTimestamp
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FetchReviewsQueryVariables,
  APITypes.FetchReviewsQuery
>;
export const fetchReviewsByUser = /* GraphQL */ `query FetchReviewsByUser($input: FetchReviewsByUserRequest!) {
  fetchReviewsByUser(input: $input) {
    reviewsRequestedByUser {
      reviewId
      reviewStatus
      reviewJson
      system
      timestamp
      requesterId
      resourceIdentifier
      reviewers
      reviewDescription
      reviewedTimestamp
      __typename
    }
    reviewsToBeReviewedByUser {
      reviewId
      reviewStatus
      reviewJson
      system
      timestamp
      requesterId
      resourceIdentifier
      reviewers
      reviewDescription
      reviewedTimestamp
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FetchReviewsByUserQueryVariables,
  APITypes.FetchReviewsByUserQuery
>;
export const checkSystemPermission = /* GraphQL */ `query CheckSystemPermission($input: CheckSystemPermissionInput!) {
  checkSystemPermission(input: $input) {
    hasAccess
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CheckSystemPermissionQueryVariables,
  APITypes.CheckSystemPermissionQuery
>;
export const fetchReviewById = /* GraphQL */ `query FetchReviewById($input: FetchReviewByIdRequest!) {
  fetchReviewById(input: $input) {
    review {
      reviewId
      reviewStatus
      reviewJson
      system
      timestamp
      requesterId
      resourceIdentifier
      reviewers
      reviewDescription
      reviewedTimestamp
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FetchReviewByIdQueryVariables,
  APITypes.FetchReviewByIdQuery
>;
export const fetchAccessibleReviews = /* GraphQL */ `query FetchAccessibleReviews($input: FetchReviewsRequest) {
  fetchAccessibleReviews(input: $input) {
    reviews {
      reviewId
      reviewStatus
      reviewJson
      system
      timestamp
      requesterId
      resourceIdentifier
      reviewers
      reviewDescription
      reviewedTimestamp
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FetchAccessibleReviewsQueryVariables,
  APITypes.FetchAccessibleReviewsQuery
>;
export const getConfiguration = /* GraphQL */ `query GetConfiguration($input: GetConfigurationRequest!) {
  getConfiguration(input: $input) {
    configs
    metadata
    user
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetConfigurationQueryVariables,
  APITypes.GetConfigurationQuery
>;
export const getListOfTenants = /* GraphQL */ `query GetListOfTenants {
  getListOfTenants {
    tenants
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetListOfTenantsQueryVariables,
  APITypes.GetListOfTenantsQuery
>;
export const getListOfConfigurations = /* GraphQL */ `query GetListOfConfigurations($input: GetListOfConfigsRequest!) {
  getListOfConfigurations(input: $input) {
    configs {
      configName
      configType
      parentConfig
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetListOfConfigurationsQueryVariables,
  APITypes.GetListOfConfigurationsQuery
>;
export const retrieveConfigurations = /* GraphQL */ `query RetrieveConfigurations(
  $tenant: String!
  $configList: [RetrieveConfigListInput]
  $inheritFlag: Boolean
) {
  retrieveConfigurations(
    tenant: $tenant
    configList: $configList
    inheritFlag: $inheritFlag
  ) {
    configsMap {
      configName
      parentConfig
      configType
      configParams
      versionDescription
      metadata
      user
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.RetrieveConfigurationsQueryVariables,
  APITypes.RetrieveConfigurationsQuery
>;
export const getListOfAccessibleTenants = /* GraphQL */ `query GetListOfAccessibleTenants($input: GetListOfAccessibleTenantsRequest!) {
  getListOfAccessibleTenants(input: $input) {
    tenants {
      tenantName
      operation
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetListOfAccessibleTenantsQueryVariables,
  APITypes.GetListOfAccessibleTenantsQuery
>;
export const GetSyncStatus = /* GraphQL */ `query GetSyncStatus {
  GetSyncStatus {
    statusCode
    data {
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSyncStatusQueryVariables,
  APITypes.GetSyncStatusQuery
>;
export const IsAuthorizedToAccessService = /* GraphQL */ `query IsAuthorizedToAccessService($input: IsAuthorizedRequest!) {
  IsAuthorizedToAccessService(input: $input) {
    isAuthorized
    __typename
  }
}
` as GeneratedQuery<
  APITypes.IsAuthorizedToAccessServiceQueryVariables,
  APITypes.IsAuthorizedToAccessServiceQuery
>;
export const GetDashboardEmbedUrl = /* GraphQL */ `query GetDashboardEmbedUrl($input: GetDashboardEmbedUrlRequest!) {
  GetDashboardEmbedUrl(input: $input) {
    url
    error {
      message
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDashboardEmbedUrlQueryVariables,
  APITypes.GetDashboardEmbedUrlQuery
>;
export const getGFTOneUserSubscription = /* GraphQL */ `query GetGFTOneUserSubscription($id: String!, $name: String) {
  getGFTOneUserSubscription(id: $id, name: $name) {
    id
    recipient
    app
    stage
    eventType
    resourceId
    channel
    createdOn
    lastModifiedOn
    expiresOn
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetGFTOneUserSubscriptionQueryVariables,
  APITypes.GetGFTOneUserSubscriptionQuery
>;
export const getGFTOneUINotificationsCount = /* GraphQL */ `query GetGFTOneUINotificationsCount($input: GFTOneNotificationsInput) {
  getGFTOneUINotificationsCount(input: $input) {
    count
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetGFTOneUINotificationsCountQueryVariables,
  APITypes.GetGFTOneUINotificationsCountQuery
>;
export const getGFTOneUINotifications = /* GraphQL */ `query GetGFTOneUINotifications($input: GFTOneNotificationsInput) {
  getGFTOneUINotifications(input: $input) {
    items {
      id
      app
      channel
      alias
      created_at
      eventType
      stageSystemKey
      seen
      read
      sender
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetGFTOneUINotificationsQueryVariables,
  APITypes.GetGFTOneUINotificationsQuery
>;
export const isMemberOfGroup = /* GraphQL */ `query IsMemberOfGroup($input: IsMemberOfGroupRequest!) {
  isMemberOfGroup(input: $input) {
    isMember
    status
    __typename
  }
}
` as GeneratedQuery<
  APITypes.IsMemberOfGroupQueryVariables,
  APITypes.IsMemberOfGroupQuery
>;
export const isAuthorized = /* GraphQL */ `query IsAuthorized($input: IsAuthorizedBindleLockRequest!) {
  isAuthorized(input: $input) {
    isAuthorized
    status
    __typename
  }
}
` as GeneratedQuery<
  APITypes.IsAuthorizedQueryVariables,
  APITypes.IsAuthorizedQuery
>;
export const canAccess = /* GraphQL */ `query CanAccess($input: CanAccessRequest!) {
  canAccess(input: $input) {
    isAuthorized
    status
    __typename
  }
}
` as GeneratedQuery<APITypes.CanAccessQueryVariables, APITypes.CanAccessQuery>;
export const checkPermission = /* GraphQL */ `query CheckPermission($input: CheckPermissionRequest!) {
  checkPermission(input: $input) {
    resourceList {
      operation
      namespace
      resourceType
      resourceName
      __typename
    }
    status
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CheckPermissionQueryVariables,
  APITypes.CheckPermissionQuery
>;
export const checkPermissionBatch = /* GraphQL */ `query CheckPermissionBatch($input: CheckPermissionBatchRequest!) {
  checkPermissionBatch(input: $input) {
    resourceTypeList {
      resourceType
      __typename
    }
    status
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CheckPermissionBatchQueryVariables,
  APITypes.CheckPermissionBatchQuery
>;
export const listMemberOfGroup = /* GraphQL */ `query ListMemberOfGroup($input: ListMemberOfGroupRequest!) {
  listMemberOfGroup(input: $input) {
    listOfMembers {
      actorID
      actorType
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMemberOfGroupQueryVariables,
  APITypes.ListMemberOfGroupQuery
>;
export const listBindleByOwner = /* GraphQL */ `query ListBindleByOwner($input: ListBindleByOwnerRequest!) {
  listBindleByOwner(input: $input) {
    listOfBindles {
      bindleID
      bindleName
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBindleByOwnerQueryVariables,
  APITypes.ListBindleByOwnerQuery
>;
export const createSIMTicket = /* GraphQL */ `query CreateSIMTicket($input: CreateSIMTicketRequest!) {
  createSIMTicket(input: $input) {
    ticketID
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CreateSIMTicketQueryVariables,
  APITypes.CreateSIMTicketQuery
>;
export const GetGroupsUserIsMemberOf = /* GraphQL */ `query GetGroupsUserIsMemberOf($userAlias: String!) {
  GetGroupsUserIsMemberOf(userAlias: $userAlias) {
    is_super_admin
    member_of_groups
    sandboxes_accessible {
      sandbox_id
      sandbox_name
      status
      is_active
      created_at
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetGroupsUserIsMemberOfQueryVariables,
  APITypes.GetGroupsUserIsMemberOfQuery
>;
export const ListSandboxWithAcl = /* GraphQL */ `query ListSandboxWithAcl($userAlias: String!) {
  ListSandboxWithAcl(userAlias: $userAlias) {
    is_super_admin
    member_of_groups
    sandboxes_accessible {
      sandbox_id
      sandbox_name
      status
      is_active
      created_at
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSandboxWithAclQueryVariables,
  APITypes.ListSandboxWithAclQuery
>;
export const CheckAdminRole = /* GraphQL */ `query CheckAdminRole($userAlias: String!) {
  CheckAdminRole(userAlias: $userAlias) {
    is_admin
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CheckAdminRoleQueryVariables,
  APITypes.CheckAdminRoleQuery
>;
export const GetSandbox = /* GraphQL */ `query GetSandbox(
  $userAlias: String!
  $sandbox_id: String!
  $is_active: Boolean
) {
  GetSandbox(
    userAlias: $userAlias
    sandbox_id: $sandbox_id
    is_active: $is_active
  ) {
    sandbox_id
    resource_key
    status_key
    resource_id
    resource_type
    is_active
    created_at
    status
    sandbox_name
    allocation_types
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSandboxQueryVariables,
  APITypes.GetSandboxQuery
>;
export const ListSandboxes = /* GraphQL */ `query ListSandboxes(
  $userAlias: String!
  $is_active: Boolean
  $filter: TableSandboxFilterRequest
  $limit: Int
  $nextToken: String
) {
  ListSandboxes(
    userAlias: $userAlias
    is_active: $is_active
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      sandbox_id
      resource_key
      status_key
      resource_id
      resource_type
      is_active
      created_at
      status
      sandbox_name
      allocation_types
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSandboxesQueryVariables,
  APITypes.ListSandboxesQuery
>;
export const GetInputContent = /* GraphQL */ `query GetInputContent(
  $sandbox_id: String!
  $input_id: String!
  $allocation_pool_filters: [String]
  $limit: Int
  $offset: Int
) {
  GetInputContent(
    sandbox_id: $sandbox_id
    input_id: $input_id
    allocation_pool_filters: $allocation_pool_filters
    limit: $limit
    offset: $offset
  ) {
    content
    last_modified
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInputContentQueryVariables,
  APITypes.GetInputContentQuery
>;
export const GetInputSchema = /* GraphQL */ `query GetInputSchema(
  $allocation_type: AllocationType!
  $input_type: InputType!
  $sandbox_id: String
  $input_id: String
) {
  GetInputSchema(
    allocation_type: $allocation_type
    input_type: $input_type
    sandbox_id: $sandbox_id
    input_id: $input_id
  ) {
    column_schema {
      name
      label
      type
      required
      defaultValue
      __typename
    }
    query_schema
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInputSchemaQueryVariables,
  APITypes.GetInputSchemaQuery
>;
export const ListInput = /* GraphQL */ `query ListInput(
  $sandbox_id: String!
  $is_active: Boolean
  $allocation_type: AllocationType
  $input_type: InputType
  $limit: Int
  $nextToken: String
) {
  ListInput(
    sandbox_id: $sandbox_id
    is_active: $is_active
    allocation_type: $allocation_type
    input_type: $input_type
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      sandbox_id
      resource_key
      status_key
      resource_id
      resource_type
      is_active
      created_at
      status
      input_type
      allocation_type
      s3_path
      userAlias
      dictionary_input_id
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListInputQueryVariables, APITypes.ListInputQuery>;
export const GetAllocationConfigContent = /* GraphQL */ `query GetAllocationConfigContent(
  $allocation_type: AllocationType!
  $input_type: InputType!
  $sandbox_id: String
  $input_id: String
  $allocation_pool_filters: [String]
  $limit: Int
  $offset: Int
) {
  GetAllocationConfigContent(
    allocation_type: $allocation_type
    input_type: $input_type
    sandbox_id: $sandbox_id
    input_id: $input_id
    allocation_pool_filters: $allocation_pool_filters
    limit: $limit
    offset: $offset
  ) {
    content
    last_modified
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllocationConfigContentQueryVariables,
  APITypes.GetAllocationConfigContentQuery
>;
export const GetAllocationConfigSchema = /* GraphQL */ `query GetAllocationConfigSchema(
  $allocation_type: AllocationType!
  $input_type: InputType!
  $sandbox_id: String
  $input_id: String
  $limit: Int
  $nextToken: String
) {
  GetAllocationConfigSchema(
    allocation_type: $allocation_type
    input_type: $input_type
    sandbox_id: $sandbox_id
    input_id: $input_id
    limit: $limit
    nextToken: $nextToken
  ) {
    column_schema {
      name
      label
      type
      required
      defaultValue
      __typename
    }
    query_schema
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAllocationConfigSchemaQueryVariables,
  APITypes.GetAllocationConfigSchemaQuery
>;
export const ListInputDictionaryDiff = /* GraphQL */ `query ListInputDictionaryDiff($sandbox_id: String, $input_id: String) {
  ListInputDictionaryDiff(sandbox_id: $sandbox_id, input_id: $input_id) {
    input_json
    input_schema {
      name
      label
      type
      required
      defaultValue
      __typename
    }
    diff
    userAlias
    created_at
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInputDictionaryDiffQueryVariables,
  APITypes.ListInputDictionaryDiffQuery
>;
export const GetSimDetails = /* GraphQL */ `query GetSimDetails(
  $sandbox_id: String!
  $sim_id: String!
  $is_active: Boolean
  $is_shared: Boolean
) {
  GetSimDetails(
    sandbox_id: $sandbox_id
    sim_id: $sim_id
    is_active: $is_active
    is_shared: $is_shared
  ) {
    sandbox_id
    resource_key
    status_key
    resource_id
    resource_type
    is_active
    name
    status
    created_at
    updated_at
    started_at
    finished_at
    error_msg
    configs
    allocation_type
    input_ids
    mwaa_env
    dag_run_id
    scenario
    timeRange
    dag_tasks_status
    report_id
    is_shared
    shared_to_sandbox_ids
    shared_from_sandbox_id
    userAlias
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSimDetailsQueryVariables,
  APITypes.GetSimDetailsQuery
>;
export const ListSimsBySandbox = /* GraphQL */ `query ListSimsBySandbox(
  $sandbox_id: String!
  $is_active: Boolean
  $is_shared: Boolean
  $filter: SimBySandboxIdStatusFilterRequest
  $limit: Int
  $nextToken: String
) {
  ListSimsBySandbox(
    sandbox_id: $sandbox_id
    is_active: $is_active
    is_shared: $is_shared
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      sandbox_id
      resource_key
      status_key
      resource_id
      resource_type
      is_active
      name
      status
      created_at
      updated_at
      started_at
      finished_at
      error_msg
      configs
      allocation_type
      input_ids
      mwaa_env
      dag_run_id
      scenario
      timeRange
      dag_tasks_status
      report_id
      is_shared
      shared_to_sandbox_ids
      shared_from_sandbox_id
      userAlias
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSimsBySandboxQueryVariables,
  APITypes.ListSimsBySandboxQuery
>;
export const ListAllSims = /* GraphQL */ `query ListAllSims(
  $is_active: Boolean
  $filter: SimBySandboxIdStatusFilterRequest
  $limit: Int
  $nextToken: String
) {
  ListAllSims(
    is_active: $is_active
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      sandbox_id
      resource_key
      status_key
      resource_id
      resource_type
      is_active
      name
      status
      created_at
      updated_at
      started_at
      finished_at
      error_msg
      configs
      allocation_type
      input_ids
      mwaa_env
      dag_run_id
      scenario
      timeRange
      dag_tasks_status
      report_id
      is_shared
      shared_to_sandbox_ids
      shared_from_sandbox_id
      userAlias
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAllSimsQueryVariables,
  APITypes.ListAllSimsQuery
>;
export const ListSimsByStatusBySandbox = /* GraphQL */ `query ListSimsByStatusBySandbox(
  $sandbox_id: String!
  $is_active: Boolean
  $is_shared: Boolean
  $status: SimStatus!
  $filter: SimBySandboxIdStatusFilterRequest
  $limit: Int
  $nextToken: String
) {
  ListSimsByStatusBySandbox(
    sandbox_id: $sandbox_id
    is_active: $is_active
    is_shared: $is_shared
    status: $status
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      sandbox_id
      resource_key
      status_key
      resource_id
      resource_type
      is_active
      name
      status
      created_at
      updated_at
      started_at
      finished_at
      error_msg
      configs
      allocation_type
      input_ids
      mwaa_env
      dag_run_id
      scenario
      timeRange
      dag_tasks_status
      report_id
      is_shared
      shared_to_sandbox_ids
      shared_from_sandbox_id
      userAlias
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSimsByStatusBySandboxQueryVariables,
  APITypes.ListSimsByStatusBySandboxQuery
>;
export const ListSimsByStatus = /* GraphQL */ `query ListSimsByStatus(
  $is_active: Boolean
  $status: SimStatus!
  $filter: SimBySandboxIdStatusFilterRequest
  $limit: Int
  $nextToken: String
) {
  ListSimsByStatus(
    is_active: $is_active
    status: $status
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      sandbox_id
      resource_key
      status_key
      resource_id
      resource_type
      is_active
      name
      status
      created_at
      updated_at
      started_at
      finished_at
      error_msg
      configs
      allocation_type
      input_ids
      mwaa_env
      dag_run_id
      scenario
      timeRange
      dag_tasks_status
      report_id
      is_shared
      shared_to_sandbox_ids
      shared_from_sandbox_id
      userAlias
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListSimsByStatusQueryVariables,
  APITypes.ListSimsByStatusQuery
>;
export const GetReportDetails = /* GraphQL */ `query GetReportDetails(
  $sandbox_id: String
  $report_id: String
  $sim_id: String
) {
  GetReportDetails(
    sandbox_id: $sandbox_id
    report_id: $report_id
    sim_id: $sim_id
  ) {
    sandbox_id
    resource_key
    status_key
    resource_id
    resource_type
    is_active
    status
    created_at
    sim_id
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReportDetailsQueryVariables,
  APITypes.GetReportDetailsQuery
>;
export const GetReportEmbedUrl = /* GraphQL */ `query GetReportEmbedUrl(
  $user_id: String!
  $sim_id: String!
  $allocation_type: AllocationType
) {
  GetReportEmbedUrl(
    user_id: $user_id
    sim_id: $sim_id
    allocation_type: $allocation_type
  )
}
` as GeneratedQuery<
  APITypes.GetReportEmbedUrlQueryVariables,
  APITypes.GetReportEmbedUrlQuery
>;
export const GetSyncTimings = /* GraphQL */ `query GetSyncTimings($allocation_type: AllocationType) {
  GetSyncTimings(allocation_type: $allocation_type) {
    allocationDefinitionSyncTime
    allocationMappingSyncTime
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSyncTimingsQueryVariables,
  APITypes.GetSyncTimingsQuery
>;
export const GetConfigurations = /* GraphQL */ `query GetConfigurations($userAlias: String!, $configType: ConfigType) {
  GetConfigurations(userAlias: $userAlias, configType: $configType) {
    content
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetConfigurationsQueryVariables,
  APITypes.GetConfigurationsQuery
>;
export const ListScenarios = /* GraphQL */ `query ListScenarios(
  $allocation_type: AllocationType
  $is_active: Boolean!
  $is_enabled: Boolean!
  $filter: TableScenarioFilterRequest
  $limit: Int
  $nextToken: String
) {
  ListScenarios(
    allocation_type: $allocation_type
    is_active: $is_active
    is_enabled: $is_enabled
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      scenario_name
      start_period
      end_period
      default_public
      created_at
      status
      status_key
      resource_key
      display_period
      resource_id
      sandbox_id
      allocation_type
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListScenariosQueryVariables,
  APITypes.ListScenariosQuery
>;
export const GetSchema = /* GraphQL */ `query GetSchema($input: GetSchemaRequest) {
  GetSchema(input: $input) {
    attributeList {
      attributeName
      attributeLabel
      attributeType
      isRequired
      isDisabled
      isHidden
      defaultValue
      description
      options
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<APITypes.GetSchemaQueryVariables, APITypes.GetSchemaQuery>;
