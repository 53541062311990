// libs

import { SxProps } from "@mui/material";

export const loadingModalContainer: SxProps = {
    width: "100vw",
    height: "100vh",
};

export const loadingModalStack: SxProps = {
    width: "100%",
};

export const loadingModal: SxProps = {
    width: "50%",

    ".MuiLinearProgress-bar": {
        width: "20%",
    },
};
