import { IAllocationType } from "@defs/Utilities";
import { AllocationType, InputType } from "@utils/appsync/schema/API";

export const ALLOCATION_TYPES: Array<IAllocationType> = [
    {
        id: 0,
        label: "Opex Allocations",
        key: AllocationType.opex_allocation,
    },
    {
        id: 1,
        label: "Manual Journal Entry",
        key: AllocationType.gl_simulation,
    },
];

export const INPUT_TYPES: Array<IAllocationType> = [
    {
        id: 0,
        label: "Definitions",
        key: InputType.allocation_definition,
    },
    {
        id: 1,
        label: "Mappings",
        key: InputType.allocation_mapping,
    },
];
