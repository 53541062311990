// libs
import { useCallback } from "react";

// services
import { MetricsService } from "@services/MetricsService";

// defs
import { StandardUnit } from "@aws-sdk/client-cloudwatch-logs";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function useMetrics() {
    // function for publishing the api failure metrics.
    const publishLatencyMetric = useCallback((metricName: string, metricValue: number, serviceName: string): void => {
        try {
            MetricsService.getMetricsServiceInstance().record({
                MetricName: metricName,
                Value: metricValue,
                Unit: StandardUnit.Milliseconds,
                Dimensions: [
                    { Name: "Service", Value: serviceName },
                    { Name: "MetricType", Value: "Latency" },
                ],
            });
        } catch (error) {
            console.warn("Failed to publish the latency metrics due to: ", error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // function for publishing the api failure metrics.
    const publishFailureMetric = useCallback((metricName: string, serviceName: string): void => {
        try {
            MetricsService.getMetricsServiceInstance().record({
                MetricName: metricName,
                Value: 1,
                Unit: StandardUnit.Count,
                Dimensions: [
                    { Name: "Service", Value: serviceName },
                    { Name: "MetricType", Value: "Failure" },
                ],
            });
        } catch (error) {
            console.warn("Failed to publish the failure metrics due to: ", error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // function for publishing the click metrics.
    const publishClickMetric = useCallback((metricName: string): void => {
        try {
            MetricsService.getMetricsServiceInstance().record({
                MetricName: metricName,
                Value: 1,
                Unit: StandardUnit.Count,
                Dimensions: [{ Name: "Application", Value: "IssueDetectionAndManagementWebsite" }],
            });
        } catch (error) {
            console.warn("Failed to publish the click metrics due to: ", error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // function for publishing the user metrics.
    const publishUserMetric = useCallback((username: string, role: string, action: string): void => {
        try {
            MetricsService.getMetricsServiceInstance().record({
                MetricName: username,
                Value: 1,
                Unit: StandardUnit.Count,
                Dimensions: [
                    { Name: "Application", Value: "IssueDetectionAndManagementWebsite" },
                    { Name: "Role", Value: role },
                    { Name: "Action", Value: action },
                ],
            });
        } catch (error) {
            console.warn("Failed to publish the user metrics due to: ", error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // function for publishing the count metrics.
    const publishCountMetric = useCallback((metricName: string): void => {
        try {
            MetricsService.getMetricsServiceInstance().record({
                MetricName: metricName,
                Value: 1,
                Unit: StandardUnit.Count,
                Dimensions: [{ Name: "Application", Value: "IssueDetectionAndManagementWebsite" }],
            });
        } catch (error) {
            console.warn("Failed to publish metrics due to: ", error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // function for publishing the page load time metrics
    const publishPageLoadTimeMetric = useCallback((metricName: string, metricValue: number): void => {
        try {
            MetricsService.getMetricsServiceInstance().record({
                MetricName: metricName,
                Value: metricValue,
                Unit: StandardUnit.Milliseconds,
                Dimensions: [{ Name: "Application", Value: "IssueDetectionAndManagementWebsite" }],
            });
        } catch (error) {
            console.warn("Failed to publish the page load metrics due to: ", error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        publishUserMetric,
        publishClickMetric,
        publishCountMetric,
        publishLatencyMetric,
        publishFailureMetric,
        publishPageLoadTimeMetric,
    };
}

export default useMetrics;
