import { createSlice } from "@reduxjs/toolkit";

export type PageTitleState = {
    title?: string;
    subTitle?: string;
    icon?: string;
    endToEnd?: boolean;
};

export const pageTitleSlice = createSlice({
    name: "pageTitle",
    initialState: {
        title: "",
        subTitle: "",
        icon: "",
        endToEnd: false,
    },
    reducers: {
        setPageTitle: (state: PageTitleState, action: { payload: PageTitleState }) => {
            state.title = action.payload.title;
            state.subTitle = action.payload.subTitle;
            state.icon = action.payload.icon;
            state.endToEnd = !!action.payload.endToEnd;
        },
    },
});

export const { setPageTitle } = pageTitleSlice.actions;

export default pageTitleSlice.reducer;
